import axios from 'axios';
import { HOST_API_KEY_CAMBRIDGE } from 'src/config-global';
import qs from 'qs';

// Create axios instance
const axiosInstance = axios.create({
  baseURL: HOST_API_KEY_CAMBRIDGE,
  paramsSerializer: {
    serialize: (params: any) => qs.stringify(params, { encode: false }).replace(/%25/gm, '%'),
  },
});

// Interceptor for response
axiosInstance.interceptors.response.use(
  (response) => response.data,
  (error) =>
    Promise.reject(
      (error.response && error.response.data) || 'Something went wrong'
    )
);

export default axiosInstance;
