import { Link, TableRow, TableCell, Button } from "@mui/material";
import moment from "moment";
import CopyToClipboard from "react-copy-to-clipboard";
import { shortenAddress } from "src/components/shortAddress";
import toast from "react-hot-toast";
import iconCopy from "../../../../assets/image-common/icn_copy.svg";
import { convertFixed } from "src/utils/formatNumber copy";
import ConfirmDialog from "src/components/confirm-dialog";
import { useState } from "react";
import { AuthService } from "src/services";

export default function InvoiceTableRow({
  row,
  selected,
  onShowTransaction,
}: any) {
  const [openConfirm, setOpenConfirm] = useState(false);
  const handleCloseConfirm = () => {
    setOpenConfirm(false);
  };

  const [openConfirmClaimTon, setOpenConfirmClaimTon] = useState(false);
  const handleCloseConfirmClaimTon = () => {
    setOpenConfirmClaimTon(false);
  };

  const handleApprove = async () => {
    const params = {
      withdrawId: row?._id,
    };
    try {
      const res: any = await AuthService.approveWithdraw(params);
      if (res.success === true) {
        toast.success("Approve successfully");
        onShowTransaction();
        setOpenConfirm(false);
      } else {
        toast.error("Approve failed");
      }
    } catch (error) {
      console.log(error);
      toast.error("Approve failed");
      setOpenConfirm(false);
    }
  };

  const handleApproveTon = async () => {
    const params = {
      withdrawId: row?._id,
    };
    try {
      const res: any = await AuthService.approveClaimTon(params);
      if (res.success === true) {
        toast.success("Approve successfully");
        onShowTransaction();
        setOpenConfirm(false);
      } else {
        toast.error("Approve failed");
      }
    } catch (error) {
      console.log(error);
      toast.error("Approve failed");
    }
  };

  const handleCheckApprove = () => {
    if (row?.status === "pending" && row?.coin !== "TON") {
      setOpenConfirm(true);
    }
    setOpenConfirmClaimTon(true);
    if (row?.status === "pending" && row?.coin === "TON") {
    }
    return;
  };

  return (
    <>
      <TableRow hover selected={selected}>
        <TableCell
          align="left"
          style={{ borderBottom: "1px dashed rgba(241, 243, 244, 1)" }}
        >
          {moment(row?.createdAt).format("DD/MM/YYYY HH:mm:ss")}
        </TableCell>

        <TableCell
          align="left"
          style={{ borderBottom: "1px dashed rgba(241, 243, 244, 1)" }}
        >
          <CopyToClipboard
            text={row?.userId?.address}
            onCopy={() => toast.success("Coppied !")}
          >
            <div style={{ display: "flex" }}>
              <span style={{ flexShrink: "0" }}>
                {shortenAddress(row?.userId?.address)}
              </span>
              <img style={{ cursor: "pointer" }} src={iconCopy} alt="Copy" />
            </div>
          </CopyToClipboard>
        </TableCell>
        <TableCell
          align="left"
          style={{ borderBottom: "1px dashed rgba(241, 243, 244, 1)" }}
        >
          {row?.type}
        </TableCell>
        <TableCell
          align="left"
          style={{ borderBottom: "1px dashed rgba(241, 243, 244, 1)" }}
        >
          ${convertFixed(row?.amount)}
        </TableCell>
        <TableCell
          align="left"
          style={{ borderBottom: "1px dashed rgba(241, 243, 244, 1)" }}
        >
          --
        </TableCell>
        <TableCell
          align="left"
          style={{ borderBottom: "1px dashed rgba(241, 243, 244, 1)" }}
        >
          {row?.coin}
        </TableCell>
        <TableCell
          align="left"
          style={{ borderBottom: "1px dashed rgba(241, 243, 244, 1)" }}
        >
          <Button
            style={{
              whiteSpace: "nowrap"
            }}
            onClick={() => {
              handleCheckApprove();
            }}
            variant="contained"
            color={row?.status === "success" ? "success" : "error"}
          >
            {row?.status}{" "}
            {row?.status === "pending" && row?.coin === "TON" ? "TON" : ""}
          </Button>
        </TableCell>
        <TableCell
          align="left"
          style={{ borderBottom: "1px dashed rgba(241, 243, 244, 1)" }}
        >
          {row?.transactionHash && row?.coin !== "TON" ? (
            <Link
              target="_blank"
              rel="noreferrer"
              href={`https://bscscan.com/tx/${row?.transactionHash}`}
            >
              {shortenAddress(row?.transactionHash)}
            </Link>
          ) : (
            <Link
              target="_blank"
              rel="noreferrer"
              href={`https://tonviewer.com/transaction/${row?.transactionHash}`}
            >
              {shortenAddress(row?.transactionHash)}
            </Link>
          )}
        </TableCell>

        <TableCell
          align="left"
          style={{ borderBottom: "1px dashed rgba(241, 243, 244, 1)" }}
        >
          {row?.fee}
        </TableCell>
        <TableCell
          align="left"
          style={{ borderBottom: "1px dashed rgba(241, 243, 244, 1)" }}
        >
          {shortenAddress(row?.address)}
        </TableCell>
      </TableRow>

      <ConfirmDialog
        open={openConfirm}
        onClose={handleCloseConfirm}
        title="Approve Withdraw"
        content={
          <p
            style={{
              textAlign: "center",
            }}
          >
            Do you want to approve this withdraw id:{" "}
            <span
              style={{
                fontWeight: "bold",
              }}
            >
              {row?._id}
            </span>
          </p>
        }
        action={
          <Button
            onClick={() => {
              handleApprove();
            }}
            variant="contained"
            color="success"
          >
            Approve
          </Button>
        }
      />

      {/* Modal approve claim TON */}
      <ConfirmDialog
        open={openConfirmClaimTon}
        onClose={handleCloseConfirmClaimTon}
        title="Approve Claim Ton"
        content={
          <p
            style={{
              textAlign: "center",
            }}
          >
            Do you want to approve claim TON for this withdraw id:{" "}
            <span
              style={{
                fontWeight: "bold",
              }}
            >
              {row?._id}
            </span>
          </p>
        }
        action={
          <Button
            onClick={() => {
              handleApproveTon();
            }}
            variant="contained"
            color="success"
          >
            Approve
          </Button>
        }
      />
    </>
  );
}
