import { TableRow, TableCell, Link, Button } from "@mui/material";
import moment from "moment";
import { shortenAddress } from "src/components/shortAddress";
import { convertFixed } from "src/utils/formatNumber copy";

export default function InvoiceTableRow({ row, selected }: any) {
  return (
    <>
      <TableRow hover selected={selected}>
        <TableCell
          align="left"
          style={{
            borderBottom: "1px dashed rgba(241, 243, 244, 1)",
          }}
        >
          <span>{shortenAddress(row?._id)}</span>
        </TableCell>
        <TableCell
          align="left"
          style={{ borderBottom: "1px dashed rgba(241, 243, 244, 1)" }}
        >
          {moment.utc(row?.created_at).format("DD/MM/YYYY") +
            " - " +
            moment.utc(row?.created_at).format("HH:mm:ss")}
        </TableCell>
        <TableCell
          align="left"
          style={{ borderBottom: "1px dashed rgba(241, 243, 244, 1)" }}
        >
          {shortenAddress(row?.userId.address)}
        </TableCell>
        <TableCell
          align="left"
          style={{ borderBottom: "1px dashed rgba(241, 243, 244, 1)" }}
        >
          {row?.coin}
        </TableCell>
        <TableCell
          align="left"
          style={{ borderBottom: "1px dashed rgba(241, 243, 244, 1)" }}
        >
          {convertFixed(row?.amount)}
        </TableCell>
        <TableCell
          align="left"
          style={{ borderBottom: "1px dashed rgba(241, 243, 244, 1)" }}
        >
          {convertFixed(row?.currentAmountTon)}
        </TableCell>
        <TableCell
          align="left"
          style={{ borderBottom: "1px dashed rgba(241, 243, 244, 1)" }}
        >
          {convertFixed(row?.currentAmountTpt)}
        </TableCell>
        <TableCell
          align="left"
          style={{ borderBottom: "1px dashed rgba(241, 243, 244, 1)" }}
        >
          {convertFixed(row?.currentAmountUsdt)}
        </TableCell>
        <TableCell
          align="left"
          style={{ borderBottom: "1px dashed rgba(241, 243, 244, 1)" }}
        >
          {convertFixed(row?.finalAmountUsdt)}
        </TableCell>
        <TableCell
          align="left"
          style={{ borderBottom: "1px dashed rgba(241, 243, 244, 1)" }}
        >
          <Button
            variant="contained"
            color={row?.isCanceled ? "success" : "error"}
          >
            {row?.isCanceled ? "True" : "False"}
          </Button>
        </TableCell>
        <TableCell
          align="left"
          style={{ borderBottom: "1px dashed rgba(241, 243, 244, 1)" }}
        >
          <Button
            variant="contained"
            color={row?.isFinishTon ? "success" : "error"}
          >
            {row?.isFinishTon ? "True" : "False"}
          </Button>
        </TableCell>
        <TableCell
          align="left"
          style={{ borderBottom: "1px dashed rgba(241, 243, 244, 1)" }}
        >
          <Button
            variant="contained"
            color={row?.isFinishUsdt ? "success" : "error"}
          >
            {row?.isFinishUsdt ? "True" : "False"}
          </Button>
        </TableCell>
        <TableCell
          align="left"
          style={{ borderBottom: "1px dashed rgba(241, 243, 244, 1)" }}
        >
          <Button variant="contained" color={row?.isPaid ? "success" : "error"}>
            {row?.isPaid ? "True" : "False"}
          </Button>
        </TableCell>
        <TableCell
          align="center"
          style={{ borderBottom: "1px dashed rgba(241, 243, 244, 1)" }}
        >
          {convertFixed(row?.claimedAmountTon)}
        </TableCell>
        <TableCell
          align="left"
          style={{ borderBottom: "1px dashed rgba(241, 243, 244, 1)" }}
        >
          {convertFixed(row?.price)}
        </TableCell>
        <TableCell
          align="left"
          style={{ borderBottom: "1px dashed rgba(241, 243, 244, 1)" }}
        >
          {row?.rate}
        </TableCell>
        <TableCell
          align="left"
          style={{ borderBottom: "1px dashed rgba(241, 243, 244, 1)" }}
        >
          <Link
            target="_blank"
            rel="noreferrer"
            href={`https://bscscan.com/tx/${row?.transactionHash}`}
          >
            {shortenAddress(row?.transactionHash)}
          </Link>
        </TableCell>
      </TableRow>
    </>
  );
}
