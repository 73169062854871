import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import FormProvider, {
  RHFRadioGroup,
  RHFTextField,
} from "src/components/hook-form";
import {
  BlockUpdateUser,
  FormSubmitButton,
  FormTitle,
  UpdateUserContainer,
} from "./styled";
import toast from "react-hot-toast";
import { AuthService } from "src/services";
import { Button } from "@mui/material";
import { convertFixed } from "src/utils/formatNumber copy";

interface FormValuesProps {
  coin: string;
  name: string;
  feeInternal: number;
  feeExternal: number;
  minWithdrawal: number;
  withdrawEnabled: boolean;
}

const FormUpdateCoin = ({ row, handleCloseUpdateUser, onShowCoins }: any) => {
  const NewAddressSchema = Yup.object().shape({
    coin: Yup.string(),
    name: Yup.string(),
    feeInternal: Yup.number(),
    feeExternal: Yup.number(),
    minWithdrawal: Yup.number(),
    withdrawEnabled: Yup.boolean(),
  });

  const defaultValues = {
    coin: row?.coin,
    name: row?.name,
    feeInternal: row?.feeInternal,
    feeExternal: row?.feeExternal,
    minWithdrawal: row?.minWithdrawal,
    withdrawEnabled: row?.withdrawEnabled,
  };

  const methods = useForm<FormValuesProps>({
    resolver: yupResolver(NewAddressSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const onSubmit = async (data: FormValuesProps) => {
    const filteredData = Object.fromEntries(
      Object.entries(data).filter(
        ([key, value]) => value !== "" && value !== null && value !== undefined
      )
    );
    try {
      AuthService.tableCoinUpdate(filteredData);
      handleCloseUpdateUser();
      setTimeout(() => {
        onShowCoins();
      }, 1000);
    } catch (error) {
      console.error(error);
      toast.error("Some invalid input");
    }
  };

  return (
    <UpdateUserContainer>
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <BlockUpdateUser>
          <FormTitle>Withdraw Enable</FormTitle>
          <RHFRadioGroup
            style={{
              flexDirection: "row",
            }}
            defaultValue={row?.withdrawEnabled}
            name="withdrawEnabled"
            options={[
              { label: "True", value: true },
              { label: "False", value: false },
            ]}
          />
        </BlockUpdateUser>
        <BlockUpdateUser className="full">
          <FormTitle>
            <span>Coin</span>
            {/* <span>{row?.coin ? row?.coin : "--"}</span> */}
          </FormTitle>
          <RHFTextField name="coin" label="Coin" />
        </BlockUpdateUser>
        <BlockUpdateUser className="full">
          <FormTitle>
            <span>Name</span>
            {/* <span>{row?.name ? row?.name : "--"}</span> */}
          </FormTitle>
          <RHFTextField name="name" label="name" />
        </BlockUpdateUser>
        <BlockUpdateUser className="full">
          <FormTitle>
            <span>Fee Internal</span>
            {/* <span>{row?.feeInternal ? row?.feeInternal : "--"}%</span> */}
          </FormTitle>
          <RHFTextField name="feeInternal" label="Fee Internal" />
        </BlockUpdateUser>
        <BlockUpdateUser className="full">
          <FormTitle>
            <span>Fee External</span>
            {/* <span>{row?.feeExternal ? row?.feeExternal : "--"}%</span> */}
          </FormTitle>
          <RHFTextField name="feeExternal" label="Fee External" />
        </BlockUpdateUser>
        <BlockUpdateUser className="full">
          <FormTitle>
            <span>Min Withdrawal</span>
            {/* <span>
              {row?.minWithdrawal ? convertFixed(row?.minWithdrawal) : "--"}
            </span> */}
          </FormTitle>
          <RHFTextField name="minWithdrawal" label="Min Withdrawal" />
        </BlockUpdateUser>
        <BlockUpdateUser className="full">
          <FormSubmitButton className="active" disabled={isSubmitting}>
            Update
          </FormSubmitButton>
          <Button
            variant="outlined"
            color="inherit"
            onClick={handleCloseUpdateUser}
          >
            Cancel
          </Button>
        </BlockUpdateUser>
      </FormProvider>
    </UpdateUserContainer>
  );
};

export default FormUpdateCoin;
