import React from 'react'
import { EmptyDataContainer } from './styled'
import nodata from '../../assets/image-common/img_no_data.webp'

const EmptyData = () => {
  return (
    <EmptyDataContainer>
      <img src={nodata} alt="Empty Data" />
    </EmptyDataContainer>
  )
}

export default EmptyData