import { Helmet } from "react-helmet-async";
import { useEffect, useRef, useState } from "react";
import sumBy from "lodash/sumBy";
// @mui
import { useTheme } from "@mui/material/styles";
import {
  Card,
  Table,
  Stack,
  Divider,
  TableBody,
  Container,
  TableContainer,
} from "@mui/material";
import { _invoices } from "../../../_mock/arrays";
// @types
// components
import Scrollbar from "../../../components/scrollbar";
import CustomBreadcrumbs from "../../../components/custom-breadcrumbs";
import { useSettingsContext } from "../../../components/settings";
import {
  useTable,
  TableNoData,
  TableHeadCustom,
  TablePaginationCustom,
} from "../../../components/table";
// sections
import InvoiceAnalytic from "../../../sections/@dashboard/invoice/InvoiceAnalytic";
import { InvoiceTableToolbar } from "../../../sections/@dashboard/invoice/list";
import { AuthService } from "src/services";
import InvoiceTableRow from "./TableRow";
import moment from "moment";

// ----------------------------------------------------------------------

const SERVICE_OPTIONS = [
  "all",
  "AFFILIATES_BONUS",
  "INVESTMENT",
  "PROFIT_DAILY",
  "BRANCH_BONUS",
  "RANKING_BONUS",
  "WITHDRAWAL",
  "CLAIM",
  "RANKING_BONUS_DAILY",
  "INVESTMENT_BONUS",
];

const TABLE_HEAD = [
  { id: "date", label: "Create Date", align: "left" },
  { id: "addressUser", label: "Address User", align: "left" },
  { id: "type", label: "Type", align: "left" },
  { id: "amout", label: "Amout USDT", align: "left", width: 140 },
  { id: "tokenValue", label: "Token Value", align: "left" },
  { id: "token", label: "Token", align: "left" },
  { id: "status", label: "Status", align: "left", width: 140 },
  { id: "tx_hash", label: "Hash", align: "left", width: 140 },
  { id: "fee", label: "Fee", align: "left", width: 140 },
  { id: "addressTo", label: "Address to", align: "left", width: 140 },
];

// ----------------------------------------------------------------------

export default function GeneralTransaction({ data }: any) {
  const { themeStretch } = useSettingsContext();
  const theme = useTheme();

  const {
    dense,
    page,
    order,
    orderBy,
    rowsPerPage,
    setPage,
    //
    selected,
    //
    onSort,
    onChangeDense,
    onChangePage,
    onChangeRowsPerPage,
  } = useTable({ defaultOrderBy: "createDate" });

  const [tableData, setTableData] = useState<any>();
  const [dataRevenue, setDataRevenue] = useState<any>();
  const [currentPage, setCurrenPage] = useState<any>(1);
  const [filterName, setFilterName] = useState("");
  const [filterStatus, setFilterStatus] = useState("");
  const [filterService, setFilterService] = useState("");
  const [filterEndDate, setFilterEndDate] = useState<any>(null);
  const [filterStartDate, setFilterStartDate] = useState<any>(null);

  const isFiltered =
    filterStatus !== "" ||
    filterName !== "" ||
    filterService !== "" ||
    (!!filterStartDate && !!filterEndDate);

  const getLengthByStatus = (status: string) =>
    tableData?.filter((item: any) => item?.remark === status)?.length;

  const getTotalPriceByStatus = (status: string) =>
    sumBy(
      tableData?.filter((item: any) => item.status === status),
      "totalPrice"
    );

  const getPercentByStatus = (status: string) =>
    (getLengthByStatus(status) / tableData?.length) * 100;

  const TABS = [
    { value: "", label: "All", color: "info", count: currentPage?.total },

    {
      value: "PACKAGE",
      label: "Package",
      color: "warning",
    },
    {
      value: "PROFIT_DAILY",
      label: "Profit Daily",
      color: "error",
    },
    {
      value: "BRANCH_BONUS",
      label: "Branch Bonus",
      color: "default",
    },
    {
      value: "STAKING",
      label: "Staking",
      color: "default",
    },
    {
      value: "WITHDRAW",
      label: "Withdrawal",
      color: "default",
    },
    /* { value: 'CLAIM', label: 'Claim', color: 'default' },*/
    {
      value: "DIRECT_COMMISSION",
      label: "Direct Commission",
      color: "default",
    },
    {
      value: "GROWTH_COMMISSION",
      label: "Growth Commission",
      color: "default",
    },

    {
      value: "INTEREST_ON_INTEREST",
      label: "Investment Bonus",
      color: "default",
    },
  ] as const;

  const handleFilterStatus = (
    event: React.SyntheticEvent<Element, Event>,
    newValue: string
  ) => {
    setPage(0);
    setFilterStatus(newValue);
  };

  const handleFilterName = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPage(0);
    setFilterName(event.target.value);
  };

  const handleFilterService = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPage(0);
    setFilterService(event.target.value);
  };

  const handleResetFilter = () => {
    setFilterName("");
    setFilterStatus("");
    setFilterService("");
    setFilterEndDate("");
    setFilterStartDate("");
  };

  // api dashboard/transaction
  const onShowTransaction = async () => {
    try {
      const params = filterName
        ? {
            page: page + 1,
            limit: rowsPerPage,
            filterBy: {
              address: filterName,
            },
          }
        : { page: page + 1, limit: rowsPerPage };
      const res: any = await AuthService.withdraw(params);
      setTableData(res?.data?.data);
      setDataRevenue(res);
      setCurrenPage(res?.data.total);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    onShowTransaction();
  }, [
    filterName,
    filterService,
    filterEndDate,
    page,
    filterStatus,
    rowsPerPage,
  ]);

  //handle scroll top
  const ref = useRef<any>(null);
  const handleScrollTop = () => {
    ref.current?.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <>
      <Helmet>
        <title> Withdraw | Ton-patrick</title>
      </Helmet>

      <Container maxWidth={themeStretch ? false : "lg"}>
        {/* <CustomBreadcrumbs
          heading="Withdraw"
          links={[
            {
              name: "Withdraw",
            },
          ]}
        /> */}

        {/* <Card sx={{ mb: 5 }}>
          <Scrollbar>
            <Stack
              direction="row"
              divider={
                <Divider
                  orientation="vertical"
                  flexItem
                  sx={{ borderStyle: "dashed" }}
                />
              }
              sx={{ py: 2 }}
            >
              <InvoiceAnalytic
                title="Total Withdraw Amount:"
                total={dataRevenue?.revenue?.totalWithdrawAmount}
                percent={getPercentByStatus("paid")}
                price={getTotalPriceByStatus("paid")}
                icon="ic:round-receipt"
                color={theme.palette.success.main}
              />

              <InvoiceAnalytic
                title="Total Withdraw Token"
                total={dataRevenue?.revenue?.totalWithdrawToken}
                percent={getPercentByStatus("paid")}
                price={getTotalPriceByStatus("paid")}
                icon="icon-park-outline:file-withdrawal"
                color={theme.palette.success.main}
              />
            </Stack>
          </Scrollbar>
        </Card> */}

        <h2 ref={ref}>Withdraw History</h2>
        <Card>
          <InvoiceTableToolbar
            isFiltered={isFiltered}
            filterName={filterName}
            filterService={filterService}
            filterEndDate={filterEndDate}
            onFilterName={handleFilterName}
            optionsService={SERVICE_OPTIONS}
            onResetFilter={handleResetFilter}
            filterStartDate={filterStartDate}
            onFilterService={handleFilterService}
            onFilterStartDate={(newValue) => {
              setFilterStartDate(moment(newValue).format("YYYY-MM-DD"));
            }}
            onFilterEndDate={(newValue) => {
              setFilterEndDate(moment(newValue).format("YYYY-MM-DD"));
            }}
            hiddenSelect={true}
            hiddenSearchDate={true}
          />

          <TableContainer sx={{ position: "relative", overflow: "unset" }}>
            <Scrollbar>
              <Table size={dense ? "small" : "medium"} sx={{ minWidth: 800 }}>
                <TableHeadCustom
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={tableData?.length}
                  numSelected={selected?.length}
                  onSort={onSort}
                />

                <TableBody>
                  {tableData && tableData?.length > 0 ? (
                    tableData?.map((row: any) => (
                      <InvoiceTableRow key={row?.transaction_id} row={row} onShowTransaction={onShowTransaction} />
                    ))
                  ) : (
                    <TableNoData isNotFound={true} />
                  )}
                </TableBody>
              </Table>
            </Scrollbar>
          </TableContainer>

          <TablePaginationCustom
            count={currentPage}
            page={page}
            rowsPerPage={rowsPerPage}
            onPageChange={onChangePage}
            onRowsPerPageChange={onChangeRowsPerPage}
            //
            dense={dense}
            onChangeDense={onChangeDense}
            onClick={handleScrollTop}
          />
        </Card>
      </Container>
    </>
  );
}
