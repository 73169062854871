import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";

const UpdateRank = ({ setSelectRank, rank }: any) => {
  const handleChange = (event: SelectChangeEvent) => {
    setSelectRank(event.target.value as string);
  };
  return (
    <div>
      <h2 style={{ textAlign: "center" }}>
        Are you sure want to update rank for this account?
      </h2>
      <FormControl fullWidth>
        <InputLabel id="demo-simple-select-label">Rank</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          defaultValue={rank}
          label="Rank"
          onChange={handleChange}
        >
          <MenuItem value={0}>0</MenuItem>
          <MenuItem value={1}>1</MenuItem>
          <MenuItem value={2}>2</MenuItem>
          <MenuItem value={3}>3</MenuItem>
          <MenuItem value={4}>4</MenuItem>
          <MenuItem value={5}>5</MenuItem>
          <MenuItem value={6}>6</MenuItem>
          <MenuItem value={7}>7</MenuItem>
        </Select>
      </FormControl>
    </div>
  );
};

export default UpdateRank;
