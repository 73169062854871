import { TableRow, TableCell } from "@mui/material";
import moment from "moment";
import CopyToClipboard from "react-copy-to-clipboard";
import { shortenAddress } from "src/components/shortAddress";
import toast from "react-hot-toast";
import iconCopy from "../../../../assets/image-common/icn_copy.svg";
import { convertFixed } from "src/utils/formatNumber copy";

export default function InvoiceTableRow({ row, selected }: any) {
  const handleSwitchType = () => {
    switch (row?.type) {
      // case "dp":
      //   return "Deposit";
      case "wd":
        return "Withdraw";
      case "tf":
        return "Transfer";
      // case "sw":
      //   return "Swap";
      case "rc":
        return "Reward Commission";
      case "dc":
        return "Direct Commission";
      case "ic":
        return "Indirect Commission";
      case "iv":
        return "Invest";
      default:
        return "";
    }
  };

  return (
    <>
      <TableRow hover selected={selected}>
        <TableCell
          align="left"
          style={{ borderBottom: "1px dashed rgba(241, 243, 244, 1)" }}
        >
          {moment.utc(row?.createdAt).format("DD/MM/YYYY") +
            " - " +
            moment.utc(row?.createdAt).format("HH:mm:ss")}
        </TableCell>
        <TableCell
          align="left"
          style={{ borderBottom: "1px dashed rgba(241, 243, 244, 1)" }}
        >
          <CopyToClipboard
            text={row?.userId?.address}
            onCopy={() => toast.success("Coppied !")}
          >
            <div style={{ display: "flex" }}>
              <span style={{ flexShrink: "0" }}>
                {shortenAddress(row?.userId?.address)}
              </span>
              <img style={{ cursor: "pointer" }} src={iconCopy} alt="Copy" />
            </div>
          </CopyToClipboard>
        </TableCell>
        <TableCell
          align="left"
          style={{ borderBottom: "1px dashed rgba(241, 243, 244, 1)" }}
        >
          {handleSwitchType()}
        </TableCell>
        <TableCell
          align="left"
          style={{ borderBottom: "1px dashed rgba(241, 243, 244, 1)" }}
        >
          {convertFixed(row?.amount)} {row?.coin === "USDT" ? "USDT" : "TPT"}
        </TableCell>
        <TableCell
          align="left"
          style={{ borderBottom: "1px dashed rgba(241, 243, 244, 1)" }}
        >
          --
        </TableCell>
        <TableCell
          align="left"
          style={{ borderBottom: "1px dashed rgba(241, 243, 244, 1)" }}
        >
          --
        </TableCell>
      </TableRow>
    </>
  );
}
