import React from 'react';
import { Backdrop, Box, Button, Fade, Modal, Typography } from '@mui/material';
import FormProvider from 'src/components/hook-form/FormProvider';
import { RHFTextField } from 'src/components/hook-form';
import { useForm } from 'react-hook-form';
import EditIcon from '@mui/icons-material/Edit';
import { AuthService } from 'src/services';
import { BlockButtons, ModalSettingContainer } from '../ModalSetting/styled';
import { FormContainer, InputRow } from '../styled';

interface ModalCreatInterface {
  name: string;
  level: number;
}

const ModalCreateLevel = ({
  open,
  setOpenModalCreate,
  handleCloseModalCreate,
  onShowLevels,
}: any) => {
  const methods = useForm<ModalCreatInterface>();
  const { handleSubmit } = methods;
  const onSubmit = async (data: ModalCreatInterface) => {
    try {
      const params = {
        name: data.name,
        level: data.level,
      };
      await AuthService.levelCreate(params);
      await onShowLevels();
      setOpenModalCreate(false);
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <ModalSettingContainer>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleCloseModalCreate}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={open}>
          <Box sx={style}>
            <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
              <FormContainer style={{ display: 'block' }}>
                <InputRow>
                  <Typography id="transition-modal-title" variant="h6" component="h2">
                    Create Level:
                  </Typography>
                </InputRow>
                <InputRow style={{ marginTop: '10px' }}>
                  <label>Name</label>
                  <RHFTextField className="full-width" name="name" />
                </InputRow>
                <InputRow style={{ marginTop: '10px' }}>
                  <label>Level</label>
                  <RHFTextField className="full-width" name="level" />
                </InputRow>
                <InputRow>
                  <BlockButtons>
                    <Button startIcon={<EditIcon />} type="submit">
                      Create
                    </Button>
                  </BlockButtons>
                </InputRow>
              </FormContainer>
            </FormProvider>
          </Box>
        </Fade>
      </Modal>
    </ModalSettingContainer>
  );
};

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 550,
  bgcolor: 'background.paper',
  border: '1px solid #000',
  p: 4,
};

export default ModalCreateLevel;
