import { yupResolver } from "@hookform/resolvers/yup";
import { Button } from "@mui/material";
import { useState } from "react";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import FormProvider, {
  RHFSelect,
  RHFTextField,
} from "src/components/hook-form";
import {
  BlockUpdateUser,
  FormTitle,
} from "src/pages/dashboard/Setting/FormUpdate/styled";
import { AuthService } from "src/services";
import * as Yup from "yup";

interface FormValuesProps {
  address: string;
  isTransfer: boolean;
  isWithdrawal: boolean;
}
const EditUser = ({ row, handleCloseEdit, onShowUser }: any) => {
  const NewAddressSchema = Yup.object().shape({
    address: Yup.string()
      .required("Please fill address")
      .min(42, "Invalid address"),
    isTransfer: Yup.boolean(),
    isWithdrawal: Yup.boolean(),
  });

  const defaultValues = {
    address: row?.address,
    isTransfer: row?.isTransfer,
    isWithdrawal: row?.isWithdrawal,
  };

  const methods = useForm<FormValuesProps>({
    resolver: yupResolver(NewAddressSchema),
    defaultValues,
  });

  const { handleSubmit } = methods;

  const options = [
    {
      value: false,
      label: "False",
    },
    {
      value: true,
      label: "True",
    },
  ];

  //handle submit
  const [isLoading, setIsLoading] = useState(false);
  const onSubmit = async (data: any) => {
    setIsLoading(true);
    try {
      const res = await AuthService.updateUser(data);
      if (res.data.message === "Success") {
        toast.success("Update user successfully");
        setIsLoading(false);
        onShowUser();
        handleCloseEdit();
      }
    } catch (error: any) {
      toast.error("Create error");
      setIsLoading(false);
    }
  };
  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <div>
        <BlockUpdateUser className="full">
          <FormTitle>
            <span>Address</span>
          </FormTitle>
          <RHFTextField
            defaultValue={row?.address}
            name="address"
            label="Address"
            placeholder="Address Wallet"
          />
        </BlockUpdateUser>
        <BlockUpdateUser className="full">
          <FormTitle>
            <span>Transfer</span>
          </FormTitle>
          <RHFSelect native name="isTransfer">
            {options &&
              options?.map((item: any, index: number) => (
                <option key={index} value={item.value}>
                  {item.label}
                </option>
              ))}
          </RHFSelect>
        </BlockUpdateUser>
        <BlockUpdateUser className="full">
          <FormTitle>
            <span>Withdrawal</span>
          </FormTitle>
          <RHFSelect native name="isWithdrawal">
            {options &&
              options?.map((item: any, index: number) => (
                <option key={index} value={item.value}>
                  {item.label}
                </option>
              ))}
          </RHFSelect>
        </BlockUpdateUser>
        <Button
          style={{
            margin: "20px 0",
            width: "100%",
          }}
          disabled={isLoading}
          variant="outlined"
          color="warning"
          type="submit"
        >
          Update
        </Button>
      </div>
    </FormProvider>
  );
};

export default EditUser;
