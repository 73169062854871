import React from 'react'
import { LoadingDataComponent } from './styled'
import ImageLoading from '../../assets/image-common/image-loading.jpeg'
import { CircularProgress } from '@mui/material'

const LoadingData = () => {
  return (
    <LoadingDataComponent>
      {/* <img src={ImageLoading} alt="Loading" /> */}
      <CircularProgress />
    </LoadingDataComponent>
  )
}

export default LoadingData