import React, { useEffect, useState } from 'react';
import { BlockButtons, ModalSettingContainer } from './styled';
import { Backdrop, Box, Button, Fade, Modal, Typography } from '@mui/material';
import FormProvider from 'src/components/hook-form/FormProvider';
import { FormContainer, InputRow } from '../styled';
import { RHFTextField } from 'src/components/hook-form';
import { useForm } from 'react-hook-form';
import EditIcon from '@mui/icons-material/Edit';
import CloseIcon from '@mui/icons-material/Close';
import { AuthService } from 'src/services';

const ModalSetting = ({ open, handleClose, row, setOpen }: any) => {
  const [id, setId] = useState()
  const methods = useForm();
  const { handleSubmit } = methods;
  const onSubmit = async (data: any) => {
    try {
      const params = {
        id,
        name: data.name,
        value: data.value
      }
      await AuthService.createUpdate(params)
      setOpen(false)
    } catch (error) {
      console.log(error)
    }
  };

  useEffect(()=>{
    if(row) {
      setId(row?.setting_id)
    }
  },row)
  return (
    <ModalSettingContainer>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={open} style={{borderRadius: '12px', overflow: 'hidden', border: 'unset'}}>
          <Box sx={style}>
            <Typography id="transition-modal-title" variant="h6" component="h2" mb={3}>
              Update ID: {row?.setting_id}
            </Typography>
            <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
              <FormContainer style={{ display: 'block' }}>
                <InputRow style={{ marginBottom: '20px' }}>
                  <RHFTextField name="name" label="Name" defaultValue={row?.name}/>
                </InputRow>
                <InputRow style={{ marginBottom: '20px' }}>
                  <RHFTextField name="value" label="Value" defaultValue={row?.value}/>
                </InputRow>
                <InputRow>
                  <BlockButtons>
                    <Button variant="outlined" color="inherit" onClick={handleClose}>
                      Cancel
                    </Button>
                    <Button type="submit">Update setting</Button>
                  </BlockButtons>
                </InputRow>
              </FormContainer>
            </FormProvider>
          </Box>
        </Fade>
      </Modal>
    </ModalSettingContainer>
  );
};

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 550,
  bgcolor: 'background.paper',
  border: '1px solid #000',
  p: 4,
};

export default ModalSetting;
