import {
  Container,
  Divider,
  Grid,
  Table,
  TableBody,
  TableContainer,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import CardCommon from "src/components/cardCommon";
import Scrollbar from "src/components/scrollbar/Scrollbar";
import { useSettingsContext } from "src/components/settings";
import {
  TableHeadCustom,
  TableNoData,
  TablePaginationCustom,
  useTable,
} from "src/components/table";
import InvoiceTableRow from "./TableRow";
import { AuthService } from "src/services";
import CustomBreadcrumbs from "src/components/custom-breadcrumbs";
import { ReportUserContainer } from "./styled";
import { InvoiceTableToolbar } from "src/sections/@dashboard/invoice/list";
import toast from "react-hot-toast";

const SERVICE_OPTIONS = ["all"];

const ReportUser = () => {
  const { themeStretch } = useSettingsContext();
  const {
    dense,
    page,
    order,
    orderBy,
    rowsPerPage,
    setPage,
    //
    selected,
    //
    onChangeDense,
    onChangePage,
    onChangeRowsPerPage,
    onSort,
  } = useTable({ defaultOrderBy: "createDate" });
  const [tableData, setTableData] = useState<any>();
  const [filterName, setFilterName] = useState("");
  const [filterStatus, setFilterStatus] = useState("");
  const [filterService, setFilterService] = useState("");
  const [filterEndDate, setFilterEndDate] = useState<any>(null);
  const [filterStartDate, setFilterStartDate] = useState<any>(null);
  const [currentPage, setCurrenPage] = useState<any>(1);

  const handleResetFilter = () => {
    setFilterName("");
    setFilterStatus("all");
    setFilterService("");
    setFilterEndDate(null);
    setFilterStartDate(null);
  };

  // filter name
  const handleFilterName = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPage(0);
    setFilterName(event.target.value);
  };

  //service
  const handleFilterService = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPage(0);
    setFilterService(event.target.value);
    if (event.target.value === "all") {
      setFilterService("");
    }
  };

  // table head
  const getTableHead = () => {
    let head = [
      { id: "date", label: "Create Date", align: "left" },
      { id: "hash", label: "Hash", align: "left" },
      { id: "addressUser", label: "Address User", align: "left" },
      { id: "type", label: "Type", align: "left" },
      { id: "amoutUsd", label: "Amout (USDT)", align: "left", width: 140 },
      { id: "token", label: "Token Value", align: "left" },
      { id: "description", label: "Description", align: "left", width: 140 },
    ];
    if (filterStatus === "PACKAGE") {
      head.push({ id: "Support", label: "Support", align: "left" });
    }
    head.push({ id: "Status", label: "Status", align: "left", width: 140 });
    return head;
  };

  const [dashboard, setDashboard] = useState({
    totalInvest: 0,
    directCommission: 0,
    dailyEarning: 0,
    growthCommission: 0,
  });

  const handleTotalInvest = async () => {
    try {
      const res = await AuthService.getTotalInvest(filterName);
      if (res) {
        setDashboard((prev: any) => ({
          ...prev,
          totalInvest: res?.data?.totalInvest,
        }));
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleDirectCommission = async () => {
    try {
      const res = await AuthService.getDirectCommission(filterName);
      if (res) {
        setDashboard((prevState) => ({
          ...prevState,
          directCommission: res?.data?.totalCommission,
        }));
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleDailyEarning = async () => {
    try {
      const res = await AuthService.getDailyEarning(filterName);
      if (res) {
        setDashboard((prevState) => ({
          ...prevState,
          dailyEarning: res?.data?.totalEarning,
        }));
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleGrowthCommission = async () => {
    try {
      const res = await AuthService.getGrowthCommission(filterName);
      if (res) {
        setDashboard((prevState) => ({
          ...prevState,
          growthCommission: res?.data?.totalCommission,
        }));
      }
    } catch (error) {
      console.log(error);
    }
  };

  // Network
  const [networkInfo, setNetworkInfo] = useState({
    totalMember: 0,
    totalRevenue: 0,
    totalCommission: 0,
  });
  const handleRevenueNetwork = async () => {
    try {
      const res = await AuthService.getRevenueNetwork(filterName);
      if (res) {
        setNetworkInfo((prev: any) => ({
          ...prev,
          totalRevenue: res.data.totalSale,
        }));
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleMemberNetwork = async () => {
    try {
      const res = await AuthService.getMemberNetwork(filterName);
      if (res) {
        setNetworkInfo((prev: any) => ({
          ...prev,
          totalMember: res.data.totalMember,
        }));
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleCommissionNetwork = async () => {
    try {
      const res = await AuthService.getCommissionNetwork(filterName);
      if (res) {
        setNetworkInfo((prev: any) => ({
          ...prev,
          totalCommission: res?.data?.totalCommission,
        }));
      }
    } catch (error) {
      console.log(error);
    }
  };

  const [balanceDB, setBalanceDB] = useState({
    balanceTPT: 0,
    balanceUSDT: 0,
  });

  const handleGetBalanceDB = async () => {
    try {
      const res = await AuthService.getBalancesDB(filterName);
      if (res) {
        setBalanceDB({
          balanceTPT: res.data.balances[1].amount,
          balanceUSDT: res.data.balances[0].amount,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const [userInfo, setUserInfo] = useState<any>();
  const handleGetInfo = async () => {
    try {
      const res = await AuthService.getInfo(filterName);
      if (res) {
        setUserInfo(res.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleTransaction = async () => {
    const params = {
      page: page + 1,
      limit: rowsPerPage,
      address: filterName,
    };
    try {
      const res = await AuthService.getBalanceHistoryUser(params);
      if (res) {
        setTableData(res?.data?.data);
        setCurrenPage(res?.data.total);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (filterName) {
      handleDirectCommission();
      handleTotalInvest();
      handleGrowthCommission();
      handleDailyEarning();
      handleRevenueNetwork();
      handleMemberNetwork();
      handleCommissionNetwork();
      handleGetBalanceDB();
      handleGetInfo();
      handleTransaction();
    }
  }, [filterName]);

  useEffect(() => {
      handleTransaction()
  },[rowsPerPage, page])

  return (
    <ReportUserContainer>
      <Helmet>
        <title> Report User | Ton-Patrick</title>
      </Helmet>
      <CustomBreadcrumbs
        heading="Report User"
        links={[
          {
            name: "Report User",
          },
        ]}
      />
      <InvoiceTableToolbar
        filterName={filterName}
        filterService={filterService}
        filterEndDate={filterEndDate}
        onFilterName={handleFilterName}
        optionsService={SERVICE_OPTIONS}
        onResetFilter={handleResetFilter}
        filterStartDate={filterStartDate}
        onFilterService={handleFilterService}
        onFilterStartDate={(newValue) => {
          setFilterStartDate(newValue);
        }}
        onFilterEndDate={(newValue) => {
          setFilterEndDate(newValue);
        }}
        hiddenSearchDate={true}
        hiddenSelect={true}
      />
      <>
        <h2>Report User</h2>
        <Container maxWidth={themeStretch ? false : "lg"}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6} md={3}>
              <CardCommon
                title="Network Member"
                total={networkInfo?.totalMember}
                image="/assets/images/card/icn_01.png"
                chart={{
                  colors: ["#E97C00"],
                  series: [22, 8, 35, 50, 82, 84, 77, 12, 87, 43],
                }}
                backgroundColor={"#FFF6D9"}
                color="#E97C00"
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <CardCommon
                title="Token Balance"
                total={balanceDB?.balanceTPT}
                image="/assets/ton_patrick_token.svg"
                chart={{
                  colors: ["#14502B"],
                  series: [22, 8, 35, 50, 82, 84, 77, 12, 87, 43],
                }}
                backgroundColor={"#DCF9E7"}
                color="#14502B"
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <CardCommon
                title="Direct Commission"
                total={dashboard?.directCommission}
                isDolar="$"
                image="/assets/images/card/icn_05.png"
                chart={{
                  colors: ["#B30000"],
                  series: [22, 8, 35, 50, 82, 84, 77, 12, 87, 43],
                }}
                backgroundColor={"#F9DCDC"}
                color="#B30000"
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <CardCommon
                title="Total Invest ($)"
                total={dashboard?.totalInvest}
                isDolar="$"
                image="/assets/usdt_icon.png"
                chart={{
                  colors: ["#14502B"],
                  series: [22, 8, 35, 50, 82, 84, 77, 12, 87, 43],
                }}
                backgroundColor={"#DCF9E7"}
                color="#14502B"
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <CardCommon
                title="Ranking"
                total={userInfo?.rank}
                image="/assets/images/card/icn_14.png"
                chart={{
                  colors: ["#E97C00"],
                  series: [22, 8, 35, 50, 82, 84, 77, 12, 87, 43],
                }}
                backgroundColor={"#FFF6D9"}
                color="#E97C00"
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <CardCommon
                title="USDT Balance"
                total={balanceDB?.balanceUSDT}
                isDolar="$"
                image="/assets/usdt_icon.png"
                chart={{
                  colors: ["#14502B"],
                  series: [22, 8, 35, 50, 82, 84, 77, 12, 87, 43],
                }}
                backgroundColor={"#DCF9E7"}
                color="#14502B"
              />
            </Grid>

            <Grid item xs={12} sm={6} md={3}>
              <CardCommon
                title="Daily Earning"
                total={dashboard?.dailyEarning}
                image="/assets/ton_patrick_token.svg"
                chart={{
                  colors: ["#B30000"],
                  series: [22, 8, 35, 50, 82, 84, 77, 12, 87, 43],
                }}
                backgroundColor={"#F9DCDC"}
                color="#B30000"
              />
            </Grid>
          </Grid>
        </Container>
        <h2>Transaction</h2>
        <Divider />
        <br />
        <TableContainer sx={{ position: "relative", overflow: "unset" }}>
          <Scrollbar>
            <Table size={dense ? "small" : "medium"} sx={{ minWidth: 800 }}>
              <TableHeadCustom
                order={order}
                orderBy={orderBy}
                headLabel={getTableHead()}
                rowCount={tableData?.length}
                numSelected={selected?.length}
                onSort={onSort}
              />
              <TableBody>
                {tableData && tableData?.length > 0 ? (
                  tableData?.map((row: any) => (
                    <InvoiceTableRow key={row?.transaction_id} row={row} />
                  ))
                ) : (
                  <TableNoData isNotFound={true} />
                )}
              </TableBody>
            </Table>
          </Scrollbar>
        </TableContainer>
        <TablePaginationCustom
          count={currentPage}
          page={page}
          rowsPerPage={rowsPerPage}
          onPageChange={onChangePage}
          onRowsPerPageChange={onChangeRowsPerPage}
          dense={dense}
          onChangeDense={onChangeDense}
        />
      </>
    </ReportUserContainer>
  );
};

export default ReportUser;
