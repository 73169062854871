import { PackageContainer } from "./styled";
import FormProvider, {
  RHFSelect,
  RHFTextField,
} from "src/components/hook-form";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { Helmet } from "react-helmet-async";
import CustomBreadcrumbs from "src/components/custom-breadcrumbs";
import {
  BlockUpdateUser,
  FormSubmitButton,
  FormTitle,
} from "../Coin/TableRow/FormUpdateCoin/styled";
import toast from "react-hot-toast";
import { AuthService } from "src/services";
import { useState } from "react";

interface FormValuesProps {
  address: string;
  amount: number;
  coin: string;
  isPaid: boolean;
}

const Package = () => {
  const optionsCoin = [
    {
      value: "USDT",
      label: "USDT",
    },
    {
      value: "TPT",
      label: "TPT",
    },
  ];

  const optionsPaid = [
    {
      value: false,
      label: "False",
    },
    {
      value: true,
      label: "True",
    },
  ];

  const NewAddressSchema = Yup.object().shape({
    address: Yup.string()
      .required("Please fill address")
      .min(42, "Invalid address"),
    amount: Yup.number().required("Please fill amount").min(1, "Minimum is 1"),
    coin: Yup.string(),
    isPaid: Yup.boolean(),
  });

  const defaultValues = {
    coin: optionsCoin[0].value,
    address: "",
    isPaid: optionsPaid[0].value,
    amount: 0,
  };

  const methods = useForm<FormValuesProps>({
    resolver: yupResolver(NewAddressSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    setValue,
  } = methods;

  //handle submit
  const [isLoading, setIsLoading] = useState(false);
  const onSubmit = async (data: any) => {
    setIsLoading(true);
    try {
      const res = await AuthService.createPackage(data);
      if (res.data.message === "Success") {
        toast.success("Create package successfully");
        setValue("address", "");
        setValue("amount", 0);
        setIsLoading(false);
      }
    } catch (error: any) {
      toast.error("Create error");
      setIsLoading(false);
    }
  };

  return (
    <>
      <Helmet>
        <title> Package | Ton-Patrick</title>
      </Helmet>

      <CustomBreadcrumbs
        heading="Active Package"
        links={[
          {
            name: "Package",
          },
        ]}
      />

      <PackageContainer>
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
          <BlockUpdateUser className="full">
            <FormTitle>
              <span>Wallet | Username</span>
            </FormTitle>
            <RHFTextField
              name="address"
              label="Address"
              placeholder="Address Wallet"
            />
          </BlockUpdateUser>
          <BlockUpdateUser className="full">
            <FormTitle>
              <span>Amount</span>
            </FormTitle>
            <RHFTextField name="amount" label="Amount" placeholder="0.00" />
          </BlockUpdateUser>
          <BlockUpdateUser className="full"></BlockUpdateUser>
          <BlockUpdateUser className="full">
            <FormTitle>
              <span>Coin type</span>
            </FormTitle>
            <RHFSelect native name="coin">
              {optionsCoin &&
                optionsCoin?.map((item: any, index: number) => (
                  <option key={index} value={item.value}>
                    {item.label}
                  </option>
                ))}
            </RHFSelect>
          </BlockUpdateUser>
          <BlockUpdateUser className="full">
            <FormTitle>
              <span>Paid</span>
            </FormTitle>
            <RHFSelect native name="isPaid">
              {optionsPaid &&
                optionsPaid?.map((item: any, index: number) => (
                  <option key={index} value={item.value}>
                    {item.label}
                  </option>
                ))}
            </RHFSelect>
          </BlockUpdateUser>
          <BlockUpdateUser className="full">
            <FormSubmitButton disabled={isLoading} className="active">
              {isLoading ? "Loading..." : "Submit"}
            </FormSubmitButton>
          </BlockUpdateUser>
        </FormProvider>
      </PackageContainer>
    </>
  );
};

export default Package;
