import ENDPOINT from "../Endpoint";
import axiosAuth from "../../utils/axiosAuth";

const AuthService = {
  login: (authBodyData: any) => axiosAuth.post(ENDPOINT.LOGIN, authBodyData),
  revenue: (authBodyData: any) =>
    axiosAuth.get(
      `${ENDPOINT.REVENUE}?filter[start_date]=${authBodyData.startDay}&filter[end_date]=${authBodyData.endDay}`
    ),
  transaction: (authBodyData: any) =>
    axiosAuth.get(
      `${ENDPOINT.TRANSACTION}?pagination[page]=${
        authBodyData.page
      }&pagination[perPage]=${
        authBodyData.perPage
      }&sort[order]=DESC&sort[field]=created_at${
        authBodyData.address
          ? `&filter[user][address]=${authBodyData.address}`
          : ""
      }${authBodyData.type ? `&filter[type]=${authBodyData.type}` : ""}${
        authBodyData.start_date
          ? `&filter[start_date]=${authBodyData.start_date}`
          : ""
      }${
        authBodyData.end_date
          ? `&filter[end_date]=${authBodyData.end_date}`
          : ""
      }`
    ),
  tableCoinUpdate: (authBodyData: any) =>
    axiosAuth.put(`${ENDPOINT.TABLECOINUPDATE}`, authBodyData),
  withdraw: (authBodyData: any) =>
    axiosAuth.post(`${ENDPOINT.TRANSACTION}`, authBodyData),
  user: (authBodyData: any) => axiosAuth.post(`${ENDPOINT.USER}`, authBodyData),
  userSetRank: (authBodyData: any) =>
    axiosAuth.post(`${ENDPOINT.USERSETRANK}`, authBodyData),
  userUpdate: (id: any, authBodyData: any) =>
    axiosAuth.patch(`${ENDPOINT.USER}/${id}`, authBodyData),
  withdrawal: (authBodyData: any) =>
    axiosAuth.get(
      `${ENDPOINT.WITHDRAWAL}?pagination[page]=${
        authBodyData.page
      }&pagination[perPage]=${
        authBodyData.perPage
      }&sort[field]=created_at&sort[order]=DESC${
        authBodyData.address
          ? `&filter[withdraw_address]=${authBodyData.address}`
          : ""
      }${authBodyData.status ? `&filter[status]=${authBodyData.status}` : ""}${
        authBodyData.start_date
          ? `&filter[start_date]=${authBodyData.start_date}`
          : ""
      }${
        authBodyData.end_date
          ? `&filter[end_date]=${authBodyData.end_date}`
          : ""
      }`
    ),
  approveWithdrawal: (id: any) =>
    axiosAuth.post(`${ENDPOINT.WITHDRAWALAPPROVE}/${id}/approve`),
  setting: () => axiosAuth.get(`${ENDPOINT.SETTING}`),
  createSetting: (authBodyData: any) =>
    axiosAuth.post(`${ENDPOINT.SETTING}`, authBodyData),
  createUpdate: (authBodyData: any) =>
    axiosAuth.patch(`${ENDPOINT.SETTING}/${authBodyData.id}`, authBodyData),
  round: (authBodyData: any) =>
    axiosAuth.get(
      `${ENDPOINT.ROUND}?pagination[page]=${authBodyData.page}&pagination[perPage]=${authBodyData.perPage}&sort[order]=DESC&sort[field]=created_at`
    ),
  withdrawalApprove: (authBodyData: any) =>
    axiosAuth.post(`${ENDPOINT.ROUND}/${authBodyData}/approve`),
  withdrawalReject: (authBodyData: any) =>
    axiosAuth.post(`${ENDPOINT.ROUND}/${authBodyData}/reject`),
  investment: (authBodyData: any) =>
    axiosAuth.post(`${ENDPOINT.INVESTMENT}`, authBodyData),
  investmentList: (authBodyData: any) =>
    axiosAuth.get(
      `${ENDPOINT.INVESTMENTLIST}?pagination[page]=${
        authBodyData.page
      }&pagination[perPage]=${
        authBodyData.perPage
      }&sort[order]=DESC&sort[field]=created_at${
        authBodyData.address
          ? `&filter[user_info][op.or][0][address][op.like]=%${authBodyData.address}%&filter[user_info][op.or][1][address][op.like]=%${authBodyData.address}%`
          : ""
      }${
        authBodyData.start_date
          ? `&filter[start_date]=${authBodyData.start_date}`
          : ""
      }${
        authBodyData.end_date
          ? `&filter[end_date]=${authBodyData.end_date}`
          : ""
      }${authBodyData.type ? `&filter[type]=${authBodyData.type}` : ""}`
    ),
  investmentCreate: (authBodyData: any) =>
    axiosAuth.post(`${ENDPOINT.INVESTMENT}`, authBodyData),
  investmentUpdate: (id: any, authBodyData: any) =>
    axiosAuth.patch(`${ENDPOINT.INVESTMENT}/${id}`, authBodyData),
  investmentAdd: (authBodyData: any) =>
    axiosAuth.post(`${ENDPOINT.INVESTMENTLIST}/manual`, authBodyData),
  level: (authBodyData: any) =>
    axiosAuth.get(
      `${ENDPOINT.LEVELS}?pagination[page]=${
        authBodyData.page
      }&pagination[perPage]=${
        authBodyData.perPage
      }&sort[order]=DESC&sort[field]=created_at${
        authBodyData.user
          ? `&filter[creator_info][address]=${authBodyData.user}`
          : ""
      }${authBodyData.type ? `&filter[type]=${authBodyData.type}` : ""}`
    ),
  levelCreate: (authBodyData: any) =>
    axiosAuth.post(`${ENDPOINT.LEVELS}`, authBodyData),
  levelUpdate: (id: any, authBodyData: any) =>
    axiosAuth.patch(`${ENDPOINT.LEVELS}/${id}`, authBodyData),
  coins: () => axiosAuth.get(`${ENDPOINT.COINS}`),
  affiliatesUp: (id: any, authBodyData: any) =>
    axiosAuth.get(
      `${ENDPOINT.AFFILIATES}/up/${id}?pagination[page]=${
        authBodyData.page
      }&pagination[perPage]=${
        authBodyData.perPage
      }&sort[order]=DESC&sort[field]=created_at${
        authBodyData.user
          ? `&filter[creator_info][address]=${authBodyData.user}`
          : ""
      }${authBodyData.type ? `&filter[type]=${authBodyData.type}` : ""}`
    ),
  affiliatesDown: (id: any, authBodyData: any) =>
    axiosAuth.get(
      `${ENDPOINT.AFFILIATES}/down/${id}?pagination[page]=${
        authBodyData.page
      }&pagination[perPage]=${
        authBodyData.perPage
      }&sort[order]=DESC&sort[field]=created_at${
        authBodyData.user
          ? `&filter[creator_info][address]=${authBodyData.user}`
          : ""
      }${authBodyData.type ? `&filter[type]=${authBodyData.type}` : ""}`
    ),
  affiliates: (authBodyData: any) =>
    axiosAuth.post(`${ENDPOINT.AFFILIATES}`, authBodyData),
  logAction: (authBodyData: any) =>
    axiosAuth.get(
      `${ENDPOINT.LOG}/action?pagination[page]=${
        authBodyData.page
      }&pagination[perPage]=${
        authBodyData.perPage
      }&sort[order]=DESC&sort[field]=created_at${
        authBodyData.address
          ? `&filter[user_info][op.or][0][address][op.like]=%${authBodyData.address}%&filter[user_info][op.or][1][address][op.like]=%${authBodyData.address}%`
          : ""
      }${
        authBodyData.action_type
          ? `&filter[op.or][0][action_type][op.like]=%${authBodyData.action_type}%&filter[op.or][1][action_type][op.like]=%${authBodyData.action_type}%`
          : ""
      }${
        authBodyData.start_date
          ? `&filter[start_date]=${authBodyData.start_date}`
          : ""
      }${
        authBodyData.end_date
          ? `&filter[end_date]=${authBodyData.end_date}`
          : ""
      }`
    ),
  logBalance: (authBodyData: any) =>
    axiosAuth.get(
      `${ENDPOINT.LOG}/balance?pagination[page]=${
        authBodyData.page
      }&pagination[perPage]=${
        authBodyData.perPage
      }&sort[order]=DESC&sort[field]=created_at${
        authBodyData.address
          ? `&filter[user_info][op.or][0][address][op.like]=%${authBodyData.address}%&filter[user_info][op.or][1][address][op.like]=%${authBodyData.address}%`
          : ""
      }${
        authBodyData.start_date
          ? `&filter[start_date]=${authBodyData.start_date}`
          : ""
      }${
        authBodyData.end_date
          ? `&filter[end_date]=${authBodyData.end_date}`
          : ""
      }`
    ),
  getTotalUser: () => axiosAuth.get(`${ENDPOINT.GET_TOTAL_USER}`),
  getDailyTotalUser: () => axiosAuth.get(`${ENDPOINT.GET_DAILY_TOTAL_USER}`),
  getTotalBalanceWallet: (params: any) =>
    axiosAuth.post(`${ENDPOINT.GET_TOTAL_BALANCE_WALLET}`, params),
  getTotalBalance: (params: any) =>
    axiosAuth.post(`${ENDPOINT.GET_TOTAL_BALANCE}`, params),
  getTotalBalanceDaily: (params: any) =>
    axiosAuth.post(`${ENDPOINT.GET_TOTAL_BALANCE_DAILY}`, params),
  getBalanceHistory: (params: any) =>
    axiosAuth.post(`${ENDPOINT.GET_BALANCE_HISTORY}`, params),
  getBalanceHistoryUser: (params: any) =>
    axiosAuth.post(`${ENDPOINT.GET_BALANCE_HISTORY_USER}`, params),
  updateSetting: (params: any) => axiosAuth.put(`${ENDPOINT.SETTING}`, params),
  createPackage: (params: any) =>
    axiosAuth.post(`${ENDPOINT.CREATE_PACKAGE}`, params),
  updateUser: (params: any) => axiosAuth.put(`${ENDPOINT.USER_UPDATE}`, params),
  getDirectCommission: (address: any) =>
    axiosAuth.get(`${ENDPOINT.DIRECT_COMMISSION}${address}`),
  getTotalInvest: (address: any) =>
    axiosAuth.get(`${ENDPOINT.TOTAL_INVEST}${address}`),
  getDailyEarning: (address: any) =>
    axiosAuth.get(`${ENDPOINT.DAILY_EARNING}${address}`),
  getGrowthCommission: (address: any) =>
    axiosAuth.get(`${ENDPOINT.GROWTH_COMMISSION}${address}`),
  getRevenueNetwork: (address: any) =>
    axiosAuth.get(`${ENDPOINT.REVENUE_NETWORK}${address}`),
  getMemberNetwork: (address: any) =>
    axiosAuth.get(`${ENDPOINT.MEMBER_NETWORK}${address}`),
  getMemberInvest: () => axiosAuth.get(`${ENDPOINT.MEMBER_INVEST}`),
  getMemberInvestDaily: () => axiosAuth.get(`${ENDPOINT.MEMBER_INVEST_DAILY}`),
  getCommissionNetwork: (address: any) =>
    axiosAuth.get(`${ENDPOINT.COMMISSION_NETWORK}${address}`),
  getBalancesDB: (address: any) =>
    axiosAuth.get(`${ENDPOINT.BALANCE_DB}${address}`),
  getInfo: (address: any) => axiosAuth.get(`${ENDPOINT.USER_INFO}${address}`),
  approveWithdraw: (params: any) =>
    axiosAuth.post(`${ENDPOINT.APPROVE_WITHDRAW}`, params),
  approveClaimTon: (param: any) =>
    axiosAuth.post(`${ENDPOINT.APPROVE_CLAIM_TON}`, param),
};
export default AuthService;
