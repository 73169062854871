const ENDPOINT = {
  // LOGIN:,
  LOGIN: "/admin/login",

  COINS: "/admin/table/coin",
  TABLECOINUPDATE: "admin/table/coin",

  // revenue: '/api/account/login',
  REVENUE: "/dashboard/revenue",

  // transaction
  TRANSACTION: "/admin/fund/withdraw-history",

  // user manage
  USER: "/admin/user/list",
  USERSETRANK: "/admin/user/set-rank",
  USER_UPDATE: "/admin/user/update-user",

  // withdrawal
  WITHDRAWAL: "/wallets/bo/withdrawal",

  // setting
  SETTING: "/admin/table/setting",

  // round
  ROUND: "/blocks",

  // withdrawal Approve
  WITHDRAWALAPPROVE: "/wallets/bo/withdrawal",
  WITHDRAWALREJECT: "/wallets/bo/withdrawal",

  // investment package
  INVESTMENT: "/admin/invest/package",

  // investment list
  INVESTMENTLIST: "/packages",

  // level
  LEVELS: "/levels",

  // affiliates
  AFFILIATES: "/admin/user/affiliate",

  // log
  LOG: "/logs",

  // User
  GET_TOTAL_USER: "/admin/user/total-user",
  GET_DAILY_TOTAL_USER: "/admin/user/daily-total-user",

  // Total Balance
  GET_TOTAL_BALANCE: "/admin/fund/total-balance",
  GET_TOTAL_BALANCE_DAILY: "/admin/fund/daily-balance",
  GET_TOTAL_BALANCE_WALLET: "/admin/fund/total-balance-wallet",

  GET_BALANCE_HISTORY: "/admin/fund/balance-history",
  GET_BALANCE_HISTORY_USER: "/fund/balance-history",

  // Package
  CREATE_PACKAGE: "/admin/package/create",

  DIRECT_COMMISSION: "/user/direct-commission?address=",
  TOTAL_INVEST: "/user/total-invest?address=",
  DAILY_EARNING: "/user/total-earning?address=",
  GROWTH_COMMISSION: "user/indirect-commission?address=",

  // Network
  REVENUE_NETWORK: "/user/total-sale?address=",
  MEMBER_NETWORK: "/user/total-member?address=",
  COMMISSION_NETWORK: "/user/direct-commission?address=",
  MEMBER_INVEST: "/admin/user/total-user-invest",
  MEMBER_INVEST_DAILY: "/admin/user/daily-total-user-invest",

  // Balance
  BALANCE_DB: "/fund/balance?address=",

  // Info
  USER_INFO: "/user/info?address=",

  // Approve withdraw
  APPROVE_WITHDRAW: "/admin/fund/approve-withdrawal",

  // Approve claimTon
  APPROVE_CLAIM_TON: "/admin/fund/approve-claim-ton"

};

export default ENDPOINT;
