import { Helmet } from "react-helmet-async";
import { useEffect, useRef, useState } from "react";
// @mui
import { Table, TableBody, TableContainer } from "@mui/material";
// routes
import { PATH_DASHBOARD } from "../../../../routes/paths";
// components
import Scrollbar from "../../../../components/scrollbar";
import CustomBreadcrumbs from "../../../../components/custom-breadcrumbs";
import {
  useTable,
  TableNoData,
  TableHeadCustom,
  TablePaginationCustom,
} from "../../../../components/table";
// sections
import { InvoiceTableToolbar } from "../../../../sections/@dashboard/invoice/list";
import { AuthService } from "src/services";
import InvoiceTableRow from "./TableRow";
import { AffiliatesContainer } from "./styled";

// ----------------------------------------------------------------------

const SERVICE_OPTIONS = ["all"];

const TABLE_HEAD = [
  { id: "date", label: "Create Date", align: "left" },
  // { id: "parentAddress", label: "Parent Address", align: "left" },
  { id: "userAddress", label: "User Address", align: "left", width: 140 },
  { id: "level", label: "Level", align: "left" },
  { id: "rank", label: "Rank", align: "left" },
  // { id: "investToken", label: "Invest Token", align: "left" },
  { id: "investUSDT", label: "Invest USDT", align: "left" },
];

// ----------------------------------------------------------------------

export default function GeneralAffiliatesLeft({ data }: any) {
  const {
    dense,
    page,
    order,
    orderBy,
    rowsPerPage,
    setPage,
    //
    selected,
    onSelectAllRows,
    //
    onSort,
    onChangeDense,
    onChangePage,
    onChangeRowsPerPage,
  } = useTable({ defaultOrderBy: "createDate" });

  const [tableData, setTableData] = useState<any>();
  const [filterName, setFilterName] = useState("");
  const [filterStatus, setFilterStatus] = useState("");
  const [filterService, setFilterService] = useState("");
  const [filterEndDate, setFilterEndDate] = useState<Date | null>(null);
  const [filterStartDate, setFilterStartDate] = useState<Date | null>(null);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrenPage] = useState<any>(1);

  const handleFilterStatus = (
    event: React.SyntheticEvent<Element, Event>,
    newValue: string
  ) => {
    setPage(0);
    setFilterStatus(newValue);
  };

  const handleFilterName = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPage(0);
    setFilterName(event.target.value);
  };

  const handleFilterService = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPage(0);
    setFilterService(event.target.value);
  };

  const onShowAffiliates = async () => {
    try {
      const params = filterName
        ? {
            page: page + 1,
            limit: rowsPerPage,
            filterBy: {
              address: filterName,
            },
          }
        : { page: page + 1, limit: rowsPerPage };
      const res: any = await AuthService.affiliates(params);
      setTableData(res?.data?.data);
      setCurrenPage(res?.data.total);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (filterName) {
      onShowAffiliates();
    }
  }, [
    filterName,
    filterService,
    filterEndDate,
    page,
    filterStatus,
    rowsPerPage,
  ]);

  const handleResetFilter = () => {
    setFilterName("");
    setFilterStatus("all");
    setFilterService("");
    setFilterEndDate(null);
    setFilterStartDate(null);
  };

  //handle scroll top
  const ref = useRef<any>(null);
  const handleScrollTop = () => {
    ref.current?.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <AffiliatesContainer>
      <Helmet>
        <title>Affiliates</title>
      </Helmet>

      <CustomBreadcrumbs
        heading="Affiliates"
        links={[
          {
            name: "Dashboard",
            href: PATH_DASHBOARD.root,
          },
          {
            name: "Affiliates",
          },
        ]}
      />
      <InvoiceTableToolbar
        filterName={filterName}
        filterService={filterService}
        filterEndDate={filterEndDate}
        onFilterName={handleFilterName}
        optionsService={SERVICE_OPTIONS}
        onResetFilter={handleResetFilter}
        filterStartDate={filterStartDate}
        onFilterService={handleFilterService}
        onFilterStartDate={(newValue) => {
          setFilterStartDate(newValue);
        }}
        onFilterEndDate={(newValue) => {
          setFilterEndDate(newValue);
        }}
        hiddenSearchDate={true}
        hiddenSelect={true}
      />
      <TableContainer sx={{ position: "relative", overflow: "unset" }}>
        {/* <TableSelectedAction
              dense={dense}
              numSelected={selected?.length}
              rowCount={tableData?.length}
              onSelectAllRows={(checked) =>
                onSelectAllRows(
                  checked,
                  tableData?.map((row: any) => row.id)
                )
              }
              action={
                <Stack direction="row">
                  <Tooltip title="Sent">
                    <IconButton color="primary">
                      <Iconify icon="ic:round-send" />
                    </IconButton>
                  </Tooltip>

                  <Tooltip title="Download">
                    <IconButton color="primary">
                      <Iconify icon="eva:download-outline" />
                    </IconButton>
                  </Tooltip>

                  <Tooltip title="Print">
                    <IconButton color="primary">
                      <Iconify icon="eva:printer-fill" />
                    </IconButton>
                  </Tooltip>

                  <Tooltip title="Delete">
                    <IconButton color="primary" onClick={handleOpenConfirm}>
                      <Iconify icon="eva:trash-2-outline" />
                    </IconButton>
                  </Tooltip>
                </Stack>
              }
            /> */}

        <Scrollbar>
          <Table size={dense ? "small" : "medium"} sx={{ minWidth: 800 }}>
            <TableHeadCustom
              order={order}
              orderBy={orderBy}
              headLabel={TABLE_HEAD}
              rowCount={tableData?.length}
              numSelected={selected?.length}
              onSort={onSort}
            />

            <TableBody>
              {tableData && tableData?.length > 0 ? (
                tableData?.map((row: any) => (
                  <InvoiceTableRow key={row?.transaction_id} row={row} />
                ))
              ) : (
                <TableNoData isNotFound={true} />
              )}
            </TableBody>
          </Table>
        </Scrollbar>
      </TableContainer>

      <TablePaginationCustom
        count={currentPage}
        page={page}
        rowsPerPage={rowsPerPage}
        onPageChange={onChangePage}
        onRowsPerPageChange={onChangeRowsPerPage}
        //
        dense={dense}
        onChangeDense={onChangeDense}
        onClick={handleScrollTop}
      />
    </AffiliatesContainer>
  );
}
