import styled from '@emotion/styled';

export const AffiliatesContainer = styled.div`
  > div {
    margin-bottom: 0;
  }
  .wrapper-inner {
    display: flex;
  }
  .content-left {
    width: 50%;
  }
  .content-right {
    width: 50%;
  }
`;
