import { Helmet } from "react-helmet-async";
import { useEffect, useState } from "react";
// @mui
import {
  Tab,
  Tabs,
  Card,
  Table,
  Divider,
  TableBody,
  Container,
  TableContainer,
  Grid,
} from "@mui/material";

import { _invoices } from "../../../_mock/arrays";
// @types
// components
import Label from "../../../components/label";
import Scrollbar from "../../../components/scrollbar";
import CustomBreadcrumbs from "../../../components/custom-breadcrumbs";
import { useSettingsContext } from "../../../components/settings";
import {
  useTable,
  TableNoData,
  TableHeadCustom,
  TablePaginationCustom,
} from "../../../components/table";
// sections
import { InvoiceTableToolbar } from "../../../sections/@dashboard/invoice/list";
import { AuthService } from "src/services";
import InvoiceTableRow from "./TableRow";
import moment from "moment";
import CardCommon from "src/components/cardCommon";

// ----------------------------------------------------------------------

const SERVICE_OPTIONS = [
  "all",
  "AFFILIATES_BONUS",
  "INVESTMENT",
  "PROFIT_DAILY",
  "BRANCH_BONUS",
  "RANKING_BONUS",
  "WITHDRAWAL",
  "CLAIM",
  "RANKING_BONUS_DAILY",
  "INVESTMENT_BONUS",
];

const TABLE_HEAD = [
  { id: "date", label: "Create Date", align: "left" },
  // { id: "addressCreator", label: "Address Creator", align: "left" },
  { id: "addressUser", label: "Address User", align: "left" },
  { id: "type", label: "Type", align: "left" },
  { id: "amoutUsd", label: "Amout", align: "left", width: 140 },
  { id: "token", label: "Token Value", align: "left" },
  { id: "description", label: "Description", align: "left", width: 140 },
];

// ----------------------------------------------------------------------

export default function GeneralDashbboard({ data }: any) {
  const { themeStretch } = useSettingsContext();
  const {
    dense,
    page,
    order,
    orderBy,
    rowsPerPage,
    setPage,
    //
    selected,
    //
    onSort,
    onChangeDense,
    onChangePage,
    onChangeRowsPerPage,
  } = useTable({ defaultOrderBy: "createDate" });

  const [tableData, setTableData] = useState<any>();
  const [currentPage, setCurrenPage] = useState<any>(1);
  const [filterName, setFilterName] = useState("");
  const [filterType, setFilterType] = useState("");

  const [filterStatus, setFilterStatus] = useState("");
  const [filterService, setFilterService] = useState("");
  const [filterEndDate, setFilterEndDate] = useState<any>(null);
  const [filterStartDate, setFilterStartDate] = useState<any>(null);
  const [currentDay, setCurrentDay] = useState<any>();

  const isFiltered =
    filterStatus !== "all" ||
    filterName !== "" ||
    filterService !== "all" ||
    (!!filterStartDate && !!filterEndDate);

  const TABS = [
    { value: "", label: "All", color: "info", count: currentPage?.total },
    {
      value: "wd",
      label: "Withdraw",
      color: "warning",
    },
    {
      value: "tf",
      label: "Transfer",
      color: "error",
    },
    {
      value: "rc",
      label: "Reward Commission",
      color: "default",
    },
    {
      value: "dc",
      label: "Direct Commission",
      color: "default",
    },
    {
      value: "ic",
      label: "Indirect Commission",
      color: "default",
    },
    {
      value: "iv",
      label: "Invest",
      color: "default",
    },
  ] as const;

  const handleFilterStatus = (
    event: React.SyntheticEvent<Element, Event>,
    newValue: string
  ) => {
    setPage(0);
    setFilterType(newValue);
  };

  const handleFilterName = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPage(0);
    setFilterName(event.target.value);
  };

  const handleFilterService = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPage(0);
    setFilterService(event.target.value);
  };

  const handleResetFilter = () => {
    setFilterName("");
    setFilterStatus("all");
    setFilterService("");
    setFilterEndDate("");
    setFilterStartDate("");
  };

  //get current day
  const getCurrentDay = () => {
    let currentDate = new Date();
    setCurrentDay(moment(currentDate).format("DD/MM/YYYY"));
  };
  useEffect(() => {
    getCurrentDay();
  }, []);

  const [userData, setUserData] = useState({
    totalUser: 0,
    dailyTotalUser: 0,
  });

  const getTotalUser = async () => {
    try {
      const res: any = await AuthService.getTotalUser();
      if (res?.success) {
        setUserData((prev: any) => ({
          ...prev,
          totalUser: res.data.total,
        }));
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getDailyTotalUser = async () => {
    try {
      const res: any = await AuthService.getDailyTotalUser();
      if (res?.success) {
        setUserData((prev: any) => ({
          ...prev,
          dailyTotalUser: res.data.total,
        }));
      }
    } catch (error) {
      console.log(error);
    }
  };

  const [balancesWallet, setBalancesWallet] = useState({
    balanceUSDT: 0,
    balanceTPT: 0,
  });

  const getBalanceWallet = async (filterByObject: any) => {
    try {
      const res = await AuthService.getTotalBalanceWallet({
        filterBy: filterByObject,
      });
      return res.data.total;
    } catch (error) {
      console.log(error);
    }
  };

  const promiseBalanceWallet = async () => {
    try {
      const [balanceTPT, balanceUSDT]: any = await Promise.all([
        getBalanceWallet({
          coin: "TPT",
        }),
        getBalanceWallet({
          coin: "USDT",
        }),
      ]);
      setBalancesWallet((prev) => ({
        ...prev,
        balanceTPT: balanceTPT,
        balanceUSDT: balanceUSDT,
      }));
    } catch (error) {
      console.log(error);
    }
  };

  const getTotalBalance = async (filterByObject: any) => {
    try {
      const res = await AuthService.getTotalBalance({
        filterBy: filterByObject,
      });
      return res.data.total;
    } catch (error) {
      console.log(error);
    }
  };

  const [totalBalance, setTotalBalance] = useState({
    totalProfitDaily: 0,
    totalInvest: 0,
    totalCommission: 0,
    totalWithdraw: 0,
  });

  const promiseTotalBalance = async () => {
    try {
      const [profitDaily, invest, commission, withdraw]: any =
        await Promise.all([
          getTotalBalance({
            type: "rc",
            coin: "TPT",
          }),
          getTotalBalance({
            type: "iv",
            coin: "USDT",
          }),
          getTotalBalance({
            type: "tc",
            coin: "USDT",
          }),
          getTotalBalance({
            type: "wd",
            coin: "USDT",
          }),
        ]);
      setTotalBalance((prev) => ({
        ...prev,
        totalProfitDaily: profitDaily,
        totalInvest: invest,
        totalCommission: commission,
        totalWithdraw: withdraw,
      }));
    } catch (error) {
      console.log(error);
    }
  };

  const [totalBalanceDaily, setTotalBalanceDaily] = useState({
    totalProfitDaily: 0,
    totalInvest: 0,
    totalCommission: 0,
    totalWithdraw: 0,
  });

  const getTotalBalanceDaily = async (filterByObject: any) => {
    try {
      const res = await AuthService.getTotalBalanceDaily({
        filterBy: filterByObject,
      });
      return res.data.total;
    } catch (error) {
      console.log(error);
    }
  };

  const promiseTotalBalanceDaily = async () => {
    try {
      const [profitDaily, invest, commission, withdraw]: any =
        await Promise.all([
          getTotalBalanceDaily({
            type: "rc",
            coin: "TPT",
          }),
          getTotalBalanceDaily({
            type: "iv",
            coin: "USDT",
          }),
          getTotalBalanceDaily({
            type: "tc",
            coin: "USDT",
          }),
          getTotalBalanceDaily({
            type: "wd",
            coin: "USDT",
          }),
        ]);
      setTotalBalanceDaily((prev) => ({
        ...prev,
        totalProfitDaily: profitDaily,
        totalInvest: invest,
        totalCommission: commission,
        totalWithdraw: withdraw,
      }));
    } catch (error) {
      console.log(error);
    }
  };

  const handleTransaction = async () => {
    const params: any = {
      page: page + 1,
      limit: rowsPerPage,
      filterBy: {},
    };
    if (filterName && filterType) {
      params.filterBy.address = filterName;
      params.filterBy.type = filterType;
    }
    if (filterName) {
      params.filterBy.address = filterName;
    }
    if (filterType) {
      params.filterBy.type = filterType;
    }
    try {
      const res = await AuthService.getBalanceHistory(params);
      if (res) {
        setTableData(res?.data?.data);
        setCurrenPage(res?.data.total);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const [memberInvest, setMemberInvest] = useState({
    total: 0,
    daily: 0,
  });

  const handleGetMemberInvest = async () => {
    try {
      const res = await AuthService.getMemberInvest();
      const resDaily = await AuthService.getMemberInvestDaily();
      setMemberInvest((prev) => ({
        ...prev,
        total: res.data.total,
        daily: resDaily.data.total,
      }));
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    promiseTotalBalance();
    getTotalUser();
    getDailyTotalUser();
    promiseBalanceWallet();
    promiseTotalBalanceDaily();
    handleGetMemberInvest();
  }, []);

  useEffect(() => {
    handleTransaction();
  }, [filterName, filterType,rowsPerPage]);

  return (
    <>
      <Helmet>
        <title> Dashboard | Ton-patrick</title>
      </Helmet>

      <Container maxWidth={themeStretch ? false : "lg"}>
        <CustomBreadcrumbs
          heading="Dashboard"
          links={[
            {
              name: "",
            },
          ]}
        />

        <h2>Total</h2>
        <Container maxWidth={themeStretch ? false : "xl"}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6} md={3}>
              <CardCommon
                title="Total Member"
                percent={2.6}
                total={userData?.totalUser}
                image="/assets/images/card/icn_01.png"
                chart={{
                  colors: ["#E97C00"],
                  series: [22, 8, 35, 50, 82, 84, 77, 12, 87, 43],
                }}
                backgroundColor={"#FFF6D9"}
                color="#E97C00"
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <CardCommon
                title="Total Wallet Token"
                total={balancesWallet?.balanceTPT}
                image="/assets/ton_patrick_token.svg"
                chart={{
                  colors: ["#B000F0"],
                  series: [22, 8, 35, 50, 82, 84, 77, 12, 87, 43],
                }}
                backgroundColor={"#E4C2FF"}
                color="#B000F0"
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <CardCommon
                title="Total Profit Daily"
                total={totalBalance?.totalProfitDaily}
                image="/assets/ton_patrick_token.svg"
                chart={{
                  colors: ["#14502B"],
                  series: [22, 8, 35, 50, 82, 84, 77, 12, 87, 43],
                }}
                backgroundColor={"#DCF9E7"}
                color="#14502B"
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <CardCommon
                title="Total Invest"
                total={totalBalance?.totalInvest}
                isDolar="$"
                image="/assets/images/card/icn_10.png"
                chart={{
                  colors: ["#14502B"],
                  series: [22, 8, 35, 50, 82, 84, 77, 12, 87, 43],
                }}
                backgroundColor={"#DCF9E7"}
                color="#14502B"
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <CardCommon
                title="Total Member Invest"
                total={memberInvest?.total}
                image="/assets/images/card/icn_02.png"
                chart={{
                  colors: ["#E97C00"],
                  series: [22, 8, 35, 50, 82, 84, 77, 12, 87, 43],
                }}
                backgroundColor={"#FFF6D9"}
                color="#E97C00"
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <CardCommon
                title="Total Wallet USDT"
                total={balancesWallet?.balanceUSDT}
                image="/assets/usdt_icon.png"
                chart={{
                  colors: ["#B000F0"],
                  series: [22, 8, 35, 50, 82, 84, 77, 12, 87, 43],
                }}
                backgroundColor={"#E4C2FF"}
                color="#B000F0"
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <CardCommon
                title="Total Commission"
                total={totalBalance?.totalCommission}
                image="/assets/images/card/icn_05.png"
                isDolar="$"
                chart={{
                  colors: ["#B30000"],
                  series: [22, 8, 35, 50, 82, 84, 77, 12, 87, 43],
                }}
                backgroundColor={"#F9DCDC"}
                color="#B30000"
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <CardCommon
                title="Total Withdrawal"
                total={totalBalance?.totalWithdraw}
                image="/assets/usdt_icon.png"
                chart={{
                  colors: ["#B30000"],
                  series: [22, 8, 35, 50, 82, 84, 77, 12, 87, 43],
                }}
                backgroundColor={"#F9DCDC"}
                color="#B30000"
              />
            </Grid>
          </Grid>
        </Container>
        <h2>Today, {currentDay}</h2>
        <Container maxWidth={themeStretch ? false : "xl"}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6} md={4}>
              <CardCommon
                title="Total Member"
                percent={2.6}
                total={userData?.dailyTotalUser}
                image="/assets/images/card/icn_01.png"
                chart={{
                  colors: ["#E97C00"],
                  series: [22, 8, 35, 50, 82, 84, 77, 12, 87, 43],
                }}
                backgroundColor={"#FFF6D9"}
                color="#E97C00"
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <CardCommon
                title="Total Profit Daily"
                percent={2.6}
                total={totalBalanceDaily?.totalProfitDaily}
                image="/assets/ton_patrick_token.svg"
                chart={{
                  colors: ["#14502B"],
                  series: [22, 8, 35, 50, 82, 84, 77, 12, 87, 43],
                }}
                backgroundColor={"#DCF9E7"}
                color="#14502B"
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <CardCommon
                title="Total Invest"
                percent={2.6}
                total={totalBalanceDaily?.totalInvest}
                isDolar="$"
                image="/assets/images/card/icn_10.png"
                chart={{
                  colors: ["#14502B"],
                  series: [22, 8, 35, 50, 82, 84, 77, 12, 87, 43],
                }}
                backgroundColor={"#DCF9E7"}
                color="#14502B"
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <CardCommon
                title="Total Member Invest"
                percent={2.6}
                total={memberInvest?.daily}
                image="/assets/images/card/icn_02.png"
                chart={{
                  colors: ["#E97C00"],
                  series: [22, 8, 35, 50, 82, 84, 77, 12, 87, 43],
                }}
                backgroundColor={"#FFF6D9"}
                color="#E97C00"
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <CardCommon
                title="Total Commission"
                percent={2.6}
                total={totalBalanceDaily?.totalCommission}
                isDolar="$"
                image="/assets/images/card/icn_03.png"
                chart={{
                  colors: ["#B30000"],
                  series: [22, 8, 35, 50, 82, 84, 77, 12, 87, 43],
                }}
                backgroundColor={"#F9DCDC"}
                color="#B30000"
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <CardCommon
                title="Total Withdrawal"
                percent={2.6}
                total={totalBalanceDaily?.totalWithdraw}
                image="/assets/usdt_icon.png"
                chart={{
                  colors: ["#B30000"],
                  series: [22, 8, 35, 50, 82, 84, 77, 12, 87, 43],
                }}
                backgroundColor={"#F9DCDC"}
                color="#B30000"
              />
            </Grid>
          </Grid>
        </Container>
        <h2>Transaction</h2>
        <Card>
          <Tabs
            value={filterStatus}
            onChange={handleFilterStatus}
            sx={{
              px: 2,
              bgcolor: "background.neutral",
            }}
          >
            {TABS.map((tab: any) => (
              <Tab
                key={tab.value}
                value={tab.value}
                label={tab.label}
                icon={
                  <>
                    {tab?.count && (
                      <Label color={tab.color} sx={{ mr: 1 }}>
                        {tab.count}
                      </Label>
                    )}
                  </>
                }
              />
            ))}
          </Tabs>
          <Divider />
          <InvoiceTableToolbar
            isFiltered={isFiltered}
            filterName={filterName}
            filterService={filterService}
            filterEndDate={filterEndDate}
            onFilterName={handleFilterName}
            optionsService={SERVICE_OPTIONS}
            onResetFilter={handleResetFilter}
            filterStartDate={filterStartDate}
            onFilterService={handleFilterService}
            onFilterStartDate={(newValue) => {
              setFilterStartDate(moment(newValue).format("YYYY-MM-DD"));
            }}
            onFilterEndDate={(newValue) => {
              setFilterEndDate(moment(newValue).format("YYYY-MM-DD"));
            }}
            hiddenSelect={true}
            hiddenSearchDate={true}
          />

          <TableContainer sx={{ position: "relative", overflow: "unset" }}>
            <Scrollbar>
              <Table size={dense ? "small" : "medium"} sx={{ minWidth: 800 }}>
                <TableHeadCustom
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={tableData?.length}
                  numSelected={selected?.length}
                  onSort={onSort}
                />

                <TableBody>
                  {tableData && tableData?.length > 0 ? (
                    tableData?.map((row: any) => <InvoiceTableRow row={row} />)
                  ) : (
                    <TableNoData isNotFound={true} />
                  )}
                </TableBody>
              </Table>
            </Scrollbar>
          </TableContainer>

          <TablePaginationCustom
            count={currentPage}
            page={page}
            rowsPerPage={rowsPerPage}
            onPageChange={onChangePage}
            onRowsPerPageChange={onChangeRowsPerPage}
            //
            dense={dense}
            onChangeDense={onChangeDense}
          />
        </Card>
      </Container>
    </>
  );
}
