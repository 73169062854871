import React, { useEffect, useState } from 'react';
import { Backdrop, Box, Button, Fade, Modal, Typography } from '@mui/material';
import FormProvider from 'src/components/hook-form/FormProvider';
import { FormContainer, InputRow } from '../styled';
import { useForm } from 'react-hook-form';
import EditIcon from '@mui/icons-material/Edit';
import { BlockButtons, ModalSettingContainer } from '../ModalSetting/styled';
import { AuthService } from 'src/services';
import { RHFTextField } from 'src/components/hook-form';

const ModalUpdateLevels = ({
  open,
  handleCloseModalUpdateInvestment,
  dataRow,
  onShowLevels,
}: any) => {
  const [id, setId] = useState();
  const methods = useForm();
  const { handleSubmit } = methods;
  const onSubmit = async (data: any) => {
    try {
      const params = {
        name: data?.name ? data?.name : dataRow?.name,
        level: Number(data?.level ? data?.level : dataRow?.level),
      };
      await AuthService.levelUpdate(id, params);
      await onShowLevels();
      handleCloseModalUpdateInvestment(false);
    } catch (error) {
      handleCloseModalUpdateInvestment(false);
      console.log(error);
    }
  };

  useEffect(() => {
    if (dataRow) {
      setId(dataRow?.level_id);
    }
  }, [dataRow]);

  return (
    <ModalSettingContainer>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleCloseModalUpdateInvestment}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={open}>
          <Box sx={style}>
            <Typography id="transition-modal-title" variant="h6" component="h2">
              Update Investment:
            </Typography>
            <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
              <FormContainer style={{ display: 'block' }}>
                <InputRow style={{ marginTop: '10px' }}>
                  <label>Name</label>
                  <RHFTextField className="full-width" name="name" defaultValue={dataRow?.name} />
                </InputRow>
                <InputRow style={{ marginTop: '10px' }}>
                  <label>Level</label>
                  <RHFTextField className="full-width" name="level" defaultValue={dataRow?.level} />
                </InputRow>
                <InputRow>
                  <BlockButtons>
                    <Button startIcon={<EditIcon />} type="submit">
                      Edit
                    </Button>
                  </BlockButtons>
                </InputRow>
              </FormContainer>
            </FormProvider>
          </Box>
        </Fade>
      </Modal>
    </ModalSettingContainer>
  );
};

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 550,
  bgcolor: 'background.paper',
  border: '1px solid #000',
  p: 4,
};

export default ModalUpdateLevels;
