import styled from "styled-components";

export const ReportUserContainer = styled.div`
  > div {
    margin-bottom: 0;
  }
`
export const Nodata = styled.div`
  text-align: center;
  position: relative;
  .table {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
  }
`