import styled from "styled-components";
import { FormContainer } from "../styled";

export const ModalSettingContainer = styled.div`
  ${FormContainer} {
    width: 100%;
    display: block;
  }
`
export const BlockButtons = styled.div`
  margin-top: 12px;
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  button {
    &:nth-child(2) {
      background-color: #000;
      color: #fff;
    }
    &:nth-child(1) {
      border: solid 1px gray;
      color: #000;
    }
  }
`