import { Helmet } from "react-helmet-async";
import { useEffect, useState } from "react";
import {
  Card,
  Table,
  Stack,
  Tooltip,
  TableBody,
  Container,
  IconButton,
  TableContainer,
} from "@mui/material";
import { PATH_DASHBOARD } from "../../../../routes/paths";
import { _invoices } from "../../../../_mock/arrays";
import Iconify from "../../../../components/iconify";
import Scrollbar from "../../../../components/scrollbar";
import CustomBreadcrumbs from "../../../../components/custom-breadcrumbs";
import { useSettingsContext } from "../../../../components/settings";
import {
  useTable,
  TableNoData,
  TableHeadCustom,
  TableSelectedAction,
  TablePaginationCustom,
} from "../../../../components/table";
// sections
import { InvoiceTableToolbar } from "../../../../sections/@dashboard/invoice/list";
import { AuthService } from "src/services";
import InvoiceTableRow from "./TableRow";

const TABLE_HEAD = [
  { id: "id", label: "ID", align: "left" },
  { id: "date", label: "Create Date", align: "left" },
  { id: "userID", label: "User ID", align: "left" },
  { id: "coin", label: "Coin", align: "left" },
  { id: "amount", label: "Amount", align: "left" },
  { id: "currentAmountTon", label: "Current Amount Ton", align: "left" },
  { id: "currentAmountTpt", label: "Current Amount Tpt", align: "left" },
  { id: "currentAmountUsdt", label: "Current Amount Usdt", align: "left" },
  { id: "finalAmountUsdt", label: "Final Amount Usdt", align: "left" },
  { id: "isCanceled", label: "Canceled", align: "left" },
  { id: "isFinishTon", label: "Finish Ton", align: "left" },
  { id: "isFinishUsdt", label: "Finish Usdt", align: "left" },
  { id: "isPaid", label: "Paid", align: "left" },
  { id: "claimAmountTon", label: "Claim Amount Ton", align: "left" },
  { id: "price", label: "Price", align: "left" },
  { id: "rate", label: "Rate", align: "left" },
  { id: "hash", label: "Hash", align: "left" },
];

export default function GeneralInvesmentPackage({ data }: any) {
  const { themeStretch } = useSettingsContext();
  const {
    dense,
    page,
    order,
    orderBy,
    rowsPerPage,
    setPage,
    selected,
    onSelectAllRows,
    onSort,
    onChangeDense,
    onChangePage,
    onChangeRowsPerPage,
  } = useTable({ defaultOrderBy: "createDate" });

  const [tableData, setTableData] = useState<any>(data);
  const [currentPage, setCurrenPage] = useState<any>(1);
  const [filterName, setFilterName] = useState("");

  const [openConfirm, setOpenConfirm] = useState(false);

  const [filterStatus, setFilterStatus] = useState("all");

  const [filterService, setFilterService] = useState("all");

  const [filterEndDate, setFilterEndDate] = useState<Date | null>(null);

  const [filterStartDate, setFilterStartDate] = useState<Date | null>(null);

  const isFiltered =
    filterStatus !== "all" ||
    filterName !== "" ||
    filterService !== "all" ||
    (!!filterStartDate && !!filterEndDate);

  const getLengthByStatus = (status: string) =>
    tableData?.filter((item: any) => item.status === status)?.length;

  const TABS = [
    { value: "all", label: "No", color: "info", count: tableData?.length },
    {
      value: "paid",
      label: "No",
      color: "success",
      count: getLengthByStatus("paid"),
    },
    {
      value: "unpaid",
      label: "No",
      color: "warning",
      count: getLengthByStatus("unpaid"),
    },
    {
      value: "overdue",
      label: "No",
      color: "error",
      count: getLengthByStatus("overdue"),
    },
    {
      value: "draft",
      label: "No",
      color: "default",
      count: getLengthByStatus("draft"),
    },
  ] as const;

  const handleOpenConfirm = () => {
    setOpenConfirm(true);
  };

  const handleCloseConfirm = () => {
    setOpenConfirm(false);
  };

  const handleFilterStatus = (
    event: React.SyntheticEvent<Element, Event>,
    newValue: string
  ) => {
    setPage(0);
    setFilterStatus(newValue);
  };

  const handleFilterName = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPage(0);
    setFilterName(event.target.value);
  };

  const handleResetFilter = () => {
    setFilterName("");
    setFilterStatus("all");
    setFilterEndDate(null);
    setFilterStartDate(null);
  };

  // show Investment list
  const onShowInvestmentPackage = async () => {
    try {
      const params = filterName
        ? {
            page: page + 1,
            limit: rowsPerPage,
            filterBy: {
              address: filterName,
            },
          }
        : { page: page + 1, limit: rowsPerPage };
      const res = await AuthService.investment(params);
      setTableData(res?.data.data);
      setCurrenPage(res.data.total);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    onShowInvestmentPackage();
  }, [page, filterName, rowsPerPage]);

  return (
    <>
      <Helmet>
        <title> Investment Package | Ton-patrick</title>
      </Helmet>

      <Container maxWidth={themeStretch ? false : "lg"}>
        <CustomBreadcrumbs
          heading="Investment Package"
          links={[
            {
              name: "Dashboard",
              href: PATH_DASHBOARD.root,
            },
            {
              name: "Investment Package",
            },
          ]}
        />

        <Card>
          <InvoiceTableToolbar
            isFiltered={isFiltered}
            filterName={filterName}
            filterEndDate={filterEndDate}
            onFilterName={handleFilterName}
            onResetFilter={handleResetFilter}
            filterStartDate={filterStartDate}
            onFilterStartDate={(newValue) => {
              setFilterStartDate(newValue);
            }}
            onFilterEndDate={(newValue) => {
              setFilterEndDate(newValue);
            }}
            hiddenSearchDate={true}
            hiddenSelect={true}
          />

          <TableContainer sx={{ position: "relative", overflow: "unset" }}>
            <TableSelectedAction
              dense={dense}
              numSelected={selected?.length}
              rowCount={tableData?.length}
              onSelectAllRows={(checked) =>
                onSelectAllRows(
                  checked,
                  tableData?.map((row: any) => row.id)
                )
              }
              action={
                <Stack direction="row">
                  <Tooltip title="Sent">
                    <IconButton color="primary">
                      <Iconify icon="ic:round-send" />
                    </IconButton>
                  </Tooltip>

                  <Tooltip title="Download">
                    <IconButton color="primary">
                      <Iconify icon="eva:download-outline" />
                    </IconButton>
                  </Tooltip>

                  <Tooltip title="Print">
                    <IconButton color="primary">
                      <Iconify icon="eva:printer-fill" />
                    </IconButton>
                  </Tooltip>

                  <Tooltip title="Delete">
                    <IconButton color="primary" onClick={handleOpenConfirm}>
                      <Iconify icon="eva:trash-2-outline" />
                    </IconButton>
                  </Tooltip>
                </Stack>
              }
            />

            <Scrollbar>
              <Table size={dense ? "small" : "medium"} sx={{ minWidth: 800 }}>
                <TableHeadCustom
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={tableData?.length}
                  numSelected={selected?.length}
                  onSort={onSort}
                />

                <TableBody>
                  {tableData && tableData?.length > 0 ? (
                    tableData?.map((row: any) => (
                      <InvoiceTableRow key={row?.user?.address} row={row} />
                    ))
                  ) : (
                    <TableNoData isNotFound={true} />
                  )}
                </TableBody>
              </Table>
            </Scrollbar>
          </TableContainer>

          <TablePaginationCustom
            count={currentPage}
            page={page}
            rowsPerPage={rowsPerPage}
            onPageChange={onChangePage}
            onRowsPerPageChange={onChangeRowsPerPage}
            dense={dense}
            onChangeDense={onChangeDense}
          />
        </Card>
      </Container>
    </>
  );
}
