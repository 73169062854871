import {
  Card,
  Container,
  Table,
  TableBody,
  TableContainer,
} from "@mui/material";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import Scrollbar from "src/components/scrollbar/Scrollbar";
import {
  useTable,
  TableNoData,
  TableHeadCustom,
} from "../../../components/table";
import { AuthService } from "src/services";
import TableRowCoin from "./TableRow";

const Coin = () => {
  const { dense, order, orderBy, selected, onSort } = useTable({
    defaultOrderBy: "createDate",
  });
  const TABLE_HEAD = [
    { id: "_id", label: "ID", align: "left" },
    { id: "addressUser", label: "Address User", align: "left" },
    { id: "coin", label: "Coin", align: "left" },
    { id: "coinName", label: "Coin Name", align: "left" },
    { id: "depositEnable", label: "Enable Deposit", align: "left" },
    { id: "feeExternal", label: "Fee External", align: "left" },
    { id: "feeInternal", label: "Fee Internal", align: "left" },
    { id: "isEnabled", label: "Enabled", align: "left" },
    { id: "minWithdrawal", label: "Min Withdrawal", align: "left" },
    {
      id: "withdrawEnabled",
      label: "Withdrawal Enabled",
      align: "left",
    },
    {
      id: "action",
      label: "Action",
      align: "left",
    },
  ];
  const [tableData, setTableData] = useState<any>([]);
  const onShowCoins = async () => {
    try {
      const res: any = await AuthService.coins();
      setTableData(res?.data?.tokenInfo);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (tableData.length < 1) {
      onShowCoins();
    }
  }, [tableData]);

  return (
    <>
      <Helmet>
        <title> Coins | Ton-patrick</title>
      </Helmet>

      <h2>Coins</h2>
      <Card>
        <TableContainer sx={{ position: "relative", overflow: "unset" }}>
          <Scrollbar>
            <Table size={dense ? "small" : "medium"} sx={{ minWidth: 800 }}>
              <TableHeadCustom
                order={order}
                orderBy={orderBy}
                headLabel={TABLE_HEAD}
                rowCount={tableData?.length}
                numSelected={selected?.length}
                onSort={onSort}
              />
              <TableBody>
                {tableData && tableData?.length > 0 ? (
                  tableData?.map((row: any) => (
                    <TableRowCoin row={row} onShowCoins={onShowCoins} />
                  ))
                ) : (
                  <TableNoData isNotFound={true} />
                )}
              </TableBody>
            </Table>
          </Scrollbar>
        </TableContainer>
      </Card>
    </>
  );
};

export default Coin;
