import { useState } from "react";
import { Button, TableRow, TableCell } from "@mui/material";
import ConfirmDialog from "src/components/confirm-dialog/ConfirmDialog";
import CopyToClipboard from "react-copy-to-clipboard";
import { shortenAddress } from "src/components/shortAddress";
import toast from "react-hot-toast";
import iconCopy from "../../../../assets/image-common/icn_copy.svg";
import EditIcon from "@mui/icons-material/Edit";
import UpdateRank from "./Update/Rank";
import { AuthService } from "src/services";
import { convertFixed } from "src/utils/formatNumber copy";
import EditUser from "./Edit";

export default function InvoiceTableRow({ row, selected, onShowUser }: any) {
  const [openConfirm, setOpenConfirm] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [selectRank, setSelectRank] = useState(0);
  const handleCloseConfirm = () => {
    setOpenConfirm(false);
  };
  const handleCloseEdit = () => {
    setOpenEdit(false);
  };

  const handleUpdateRank = async () => {
    const param = {
      address: row.address,
      rank: selectRank,
    };
    try {
      const res = await AuthService.userSetRank(param);
      if (res.data.message === "Success") {
        onShowUser();
        setOpenConfirm(false);
        toast.success("Update successfully");
      }
    } catch (error) {
      console.log(error);
      toast.error("Update rank failed");
    }
  };

  return (
    <>
      <TableRow hover selected={selected}>
        <TableCell
          align="left"
          style={{ borderBottom: "1px dashed rgba(241, 243, 244, 1)" }}
        >
          {shortenAddress(row._id)}
        </TableCell>
        <TableCell
          align="left"
          style={{
            width: "120px",
            borderBottom: "1px dashed rgba(241, 243, 244, 1)",
          }}
        >
          <CopyToClipboard
            text={row?.address}
            onCopy={() => toast.success("Coppied !")}
          >
            <div style={{ display: "flex" }}>
              <span>{shortenAddress(row?.address)}</span>
              <img style={{ cursor: "pointer" }} src={iconCopy} alt="Copy" />
            </div>
          </CopyToClipboard>
        </TableCell>
        <TableCell
          align="left"
          style={{
            borderBottom: "1px dashed rgba(241, 243, 244, 1)",
          }}
        >
          <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
            <span>{row.rank}</span>
            <div
              style={{
                cursor: "pointer",
              }}
              onClick={() => {
                setOpenConfirm(true);
              }}
            >
              <EditIcon
                sx={{
                  width: "16px",
                  height: "16px",
                }}
              />
            </div>
          </div>
        </TableCell>
        {/* <TableCell
          align="left"
          style={{ borderBottom: "1px dashed rgba(241, 243, 244, 1)" }}
        >
          --
        </TableCell> */}
        <TableCell
          align="left"
          style={{ borderBottom: "1px dashed rgba(241, 243, 244, 1)" }}
        >
          ${convertFixed(row.investmentUsdt)}
        </TableCell>
        <TableCell
          align="left"
          style={{ borderBottom: "1px dashed rgba(241, 243, 244, 1)" }}
        >
          {convertFixed(row.balanceTPT)}
        </TableCell>
        <TableCell
          align="left"
          style={{ borderBottom: "1px dashed rgba(241, 243, 244, 1)" }}
        >
          {convertFixed(row.balanceUSDT)}
        </TableCell>
        <TableCell
          align="left"
          style={{ borderBottom: "1px dashed rgba(241, 243, 244, 1)" }}
        >
          {convertFixed(row.dailyProfitTPT)}
        </TableCell>
        <TableCell
          align="left"
          style={{ borderBottom: "1px dashed rgba(241, 243, 244, 1)" }}
        >
          {convertFixed(row.totalRevenueUSDT)}
        </TableCell>
        {/* <TableCell
          align="left"
          style={{ borderBottom: "1px dashed rgba(241, 243, 244, 1)" }}
        >
          --
        </TableCell> */}
        <TableCell
          align="left"
          style={{ borderBottom: "1px dashed rgba(241, 243, 244, 1)" }}
        >
          {shortenAddress(row?.referrer)}
        </TableCell>
        <TableCell
          align="left"
          style={{ borderBottom: "1px dashed rgba(241, 243, 244, 1)" }}
        >
          --
        </TableCell>
        <TableCell
          align="left"
          style={{ borderBottom: "1px dashed rgba(241, 243, 244, 1)" }}
        >
          --
        </TableCell>
        <TableCell
          align="left"
          style={{ borderBottom: "1px dashed rgba(241, 243, 244, 1)" }}
        >
          <Button variant="contained" color={row?.isWithdrawal ? "success" : "error"}>
            {row?.isWithdrawal ? "True" : "False"}
          </Button>
        </TableCell>
        <TableCell
          align="left"
          style={{ borderBottom: "1px dashed rgba(241, 243, 244, 1)" }}
        >
          <Button variant="contained" color={row?.isTransfer ? "success" : "error"}>
            {row?.isTransfer ? "True" : "False"}
          </Button>
        </TableCell>
        <TableCell
          align="left"
          style={{ borderBottom: "1px dashed rgba(241, 243, 244, 1)" }}
        >
          <div
            style={{
              cursor: "pointer",
            }}
            onClick={() => {
              setOpenEdit(true);
            }}
          >
            <EditIcon
              sx={{
                width: "16px",
                height: "16px",
              }}
            />
          </div>
        </TableCell>
      </TableRow>

      {/* Form edit */}
      <ConfirmDialog
        open={openEdit}
        onClose={handleCloseEdit}
        title=""
        content={
          <EditUser
            row={row}
            handleCloseEdit={handleCloseEdit}
            onShowUser={onShowUser}
          />
        }
        action={false}
        disabledButton={true}
      />

      {/* Update rank */}
      <ConfirmDialog
        open={openConfirm}
        onClose={handleCloseConfirm}
        title=""
        content={<UpdateRank setSelectRank={setSelectRank} rank={row?.rank} />}
        action={
          <Button
            variant="contained"
            color="success"
            onClick={handleUpdateRank}
          >
            Yes
          </Button>
        }
      />
    </>
  );
}
