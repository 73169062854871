import styled from "styled-components";

export const UpdateUserContainer = styled.div`
  padding-bottom: 20px;
  form {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
`;
export const BlockUpdateUser = styled.div`
  display: flex;
  flex-direction: column;
  &.full {
    width: 100%;
  }
  button {
    &:last-child {
      margin-top: 15px;
    }
  }
  & > p {
    display: flex;
    justify-content: space-between;
    gap: 10px;
    span {
      &:nth-child(2) {
        color: rgba(246, 131, 0, 0.867);
      }
    }
  }
`;
export const FormTitle = styled.p`
  font-weight: bold;
`;
export const FormSubmitButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  padding: 12px 20px;
  font-size: 15px;
  font-weight: bold;
  text-transform: capitalize;
  color: #fff;
  cursor: pointer;
  border-radius: 8px;
  transition: all 0.15s linear;
  outline: none;
  border: none;
  background-color: #d39c3e;
  margin-top: 20px;
`;
