import { useState } from "react";
import { Button, TableRow, TableCell } from "@mui/material";
import ConfirmDialog from "src/components/confirm-dialog/ConfirmDialog";
import moment from "moment";
import CopyToClipboard from "react-copy-to-clipboard";
import { shortenAddress } from "src/components/shortAddress";
import toast from "react-hot-toast";
import iconCopy from "../../../../assets/image-common/icn_copy.svg";
import Label from "src/components/label";
import FormUpdateUser from "../FormUpdate";

export default function InvoiceTableRow({
  row,
  selected,
  onShowSettingShow,
}: any) {
  const [openUpdate, setOpenUpdate] = useState(false);

  //modal update
  const handleCloseUpdate = () => {
    setOpenUpdate(false);
  };
  const handleOpenFormUpdate = () => {
    setOpenUpdate(true);
  };

  return (
    <>
      <TableRow hover selected={selected}>
        <TableCell
          align="left"
          style={{ borderBottom: "1px dashed rgba(241, 243, 244, 1)" }}
        >
          {moment(row?.created_at).format("DD/MM/YYYY") +
            " - " +
            moment(row?.created_at).format("HH:mm:ss")}
        </TableCell>
        <TableCell
          align="left"
          style={{ borderBottom: "1px dashed rgba(241, 243, 244, 1)" }}
        >
          <CopyToClipboard
            text={row?._id}
            onCopy={() => toast.success("Coppied !")}
          >
            <div style={{ display: "flex" }}>
              <span>{shortenAddress(row?._id)}</span>
              <img style={{ cursor: "pointer" }} src={iconCopy} alt="Copy" />
            </div>
          </CopyToClipboard>
        </TableCell>
        <TableCell
          align="left"
          style={{ borderBottom: "1px dashed rgba(241, 243, 244, 1)" }}
        >
          {row?.TPTPrice}
        </TableCell>
        <TableCell
          align="left"
          style={{ borderBottom: "1px dashed rgba(241, 243, 244, 1)" }}
        >
          {row?.dailyProfitPercent}
        </TableCell>
        <TableCell
          align="left"
          style={{ borderBottom: "1px dashed rgba(241, 243, 244, 1)" }}
        >
          {row?.maxOutTONPercent}
        </TableCell>
        <TableCell
          align="left"
          style={{ borderBottom: "1px dashed rgba(241, 243, 244, 1)" }}
        >
          {row?.maxOutUSDTPercent}
        </TableCell>
        <TableCell
          align="left"
          style={{ borderBottom: "1px dashed rgba(241, 243, 244, 1)" }}
        >
          {shortenAddress(row?.rootUser)}
        </TableCell>
        <TableCell
          style={{ borderBottom: "1px dashed rgba(241, 243, 244, 1)" }}
        >
          <Label
            variant="filled"
            color={"warning"}
            onClick={() => handleOpenFormUpdate()}
            style={{ cursor: "pointer" }}
          >
            Edit
          </Label>
        </TableCell>
      </TableRow>

      {/* mobdal Update */}
      <ConfirmDialog
        open={openUpdate}
        onClose={handleCloseUpdate}
        title="Update"
        content={
          <FormUpdateUser
            row={row}
            handleCloseUpdate={handleCloseUpdate}
            onShowSettingShow={onShowSettingShow}
          />
        }
        disabledButton={true}
        action={false}
      />
    </>
  );
}
