import { Helmet } from "react-helmet-async";
import { useEffect, useState } from "react";
import {
  Tab,
  Tabs,
  Card,
  Table,
  Stack,
  Tooltip,
  Divider,
  TableBody,
  Container,
  IconButton,
  TableContainer,
} from "@mui/material";
import { PATH_DASHBOARD } from "../../../routes/paths";
import { _invoices } from "../../../_mock/arrays";
import Label from "../../../components/label";
import Iconify from "../../../components/iconify";
import Scrollbar from "../../../components/scrollbar";
import CustomBreadcrumbs from "../../../components/custom-breadcrumbs";
import { useSettingsContext } from "../../../components/settings";
import {
  useTable,
  TableNoData,
  TableHeadCustom,
  TableSelectedAction,
} from "../../../components/table";
import { AuthService } from "src/services";
import InvoiceTableRow from "./TableRow";
import ModalSetting from "../ModalSetting";
import ModalCreate from "../ModalSetting/ModalCreate";

const TABLE_HEAD = [
  { id: "date", label: "Create Date", align: "left" },
  { id: "settingId", label: "Setting Id", align: "left" },
  { id: "tptprice", label: "TPT Price", align: "left" },
  { id: "dailyprofit", label: "Daily Profit %", align: "left" },
  { id: "maxoutTon", label: "Max out Ton %", align: "left" },
  { id: "maxoutUSDT", label: "Max out USDT %", align: "left" },
  { id: "rootUser", label: "Root User", align: "left" },
  { id: "edit", label: "Edit", align: "left" },
];

export default function GeneralSetting({ data }: any) {
  const { themeStretch } = useSettingsContext();

  const {
    dense,
    page,
    order,
    orderBy,
    setPage,
    //
    selected,
    onSelectAllRows,
    //
    onSort,
  } = useTable({ defaultOrderBy: "createDate" });

  const [tableData, setTableData] = useState<any>(data);
  const [currentPage, setCurrenPage] = useState<any>(1);
  const [filterName, setFilterName] = useState("");

  const [filterStatus, setFilterStatus] = useState("all");

  const [filterService, setFilterService] = useState("all");

  const [filterEndDate, setFilterEndDate] = useState<Date | null>(null);

  const [filterStartDate, setFilterStartDate] = useState<Date | null>(null);
  const [open, setOpen] = useState(false);
  const [openModalCreate, setOpenModalCreate] = useState(false);
  const [modalData, setModalData] = useState<any>();
  const getLengthByStatus = (status: string) =>
    tableData?.filter((item: any) => item.status === status)?.length;

  const TABS = [
    { value: "all", label: "All", color: "info", count: currentPage?.total },
  ] as const;

  const handleFilterStatus = (
    event: React.SyntheticEvent<Element, Event>,
    newValue: string
  ) => {
    setPage(0);
    setFilterStatus(newValue);
  };

  const handleFilterName = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPage(0);
    setFilterName(event.target.value);
  };

  const handleFilterService = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPage(0);
    setFilterService(event.target.value);
  };

  const handleResetFilter = () => {
    setFilterName("");
    setFilterStatus("all");
    setFilterService("all");
    setFilterEndDate(null);
    setFilterStartDate(null);
  };

  // show Investment list
  const onShowSettingShow = async () => {
    try {
      const res = await AuthService.setting();
      setTableData(res?.data.setting);
      setCurrenPage(res);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    onShowSettingShow();
  }, []);

  //modal
  const handleOpen = (data: any) => {
    setOpen(true);
    setModalData(data);
  };
  const handleClose = () => setOpen(false);
  const handleCloseModalCreate = () => setOpenModalCreate(false);

  //handle create
  const handleCreateSetting = () => {
    setOpenModalCreate(true);
  };

  return (
    <>
      <Helmet>
        <title> Setting | Ton-Patrick</title>
      </Helmet>

      <Container maxWidth={themeStretch ? false : "lg"}>
        <CustomBreadcrumbs
          heading="Setting"
          links={[
            {
              name: "Dashboard",
              href: PATH_DASHBOARD.root,
            },
            {
              name: "Setting",
            },
          ]}
        />

        <Card>
          <Tabs
            value={filterStatus}
            onChange={handleFilterStatus}
            sx={{
              px: 2,
              bgcolor: "background.neutral",
            }}
          >
            {TABS.map((tab) => (
              <Tab
                key={tab.value}
                value={tab.value}
                label={tab.label}
                icon={
                  <Label color={tab.color} sx={{ mr: 1 }}>
                    {tab.count}
                  </Label>
                }
              />
            ))}
          </Tabs>
          <Divider />

          <TableContainer sx={{ position: "relative", overflow: "unset" }}>
            <TableSelectedAction
              dense={dense}
              numSelected={selected?.length}
              rowCount={tableData?.length}
              onSelectAllRows={(checked) =>
                onSelectAllRows(
                  checked,
                  tableData?.map((row: any) => row.id)
                )
              }
              action={
                <Stack direction="row">
                  <Tooltip title="Sent">
                    <IconButton color="primary">
                      <Iconify icon="ic:round-send" />
                    </IconButton>
                  </Tooltip>

                  <Tooltip title="Download">
                    <IconButton color="primary">
                      <Iconify icon="eva:download-outline" />
                    </IconButton>
                  </Tooltip>

                  <Tooltip title="Print">
                    <IconButton color="primary">
                      <Iconify icon="eva:printer-fill" />
                    </IconButton>
                  </Tooltip>

                  <Tooltip title="Delete">
                    <IconButton color="primary">
                      <Iconify icon="eva:trash-2-outline" />
                    </IconButton>
                  </Tooltip>
                </Stack>
              }
            />

            <Scrollbar>
              <Table size={dense ? "small" : "medium"} sx={{ minWidth: 800 }}>
                <TableHeadCustom
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={tableData?.length}
                  numSelected={selected?.length}
                  onSort={onSort}
                />

                <TableBody>
                  {tableData && tableData?.length > 0 ? (
                    tableData?.map((row: any) => (
                      <InvoiceTableRow
                        key={row?.user?.address}
                        row={row}
                        setOpen={handleOpen}
                        open={open}
                        setModalData={setModalData}
                        onShowSettingShow={onShowSettingShow}
                      />
                    ))
                  ) : (
                    <TableNoData isNotFound={true} />
                  )}
                </TableBody>
              </Table>
            </Scrollbar>
          </TableContainer>
        </Card>
      </Container>
      <ModalSetting
        open={open}
        handleClose={handleClose}
        row={modalData}
        setOpen={setOpen}
      />
      <ModalCreate
        open={openModalCreate}
        handleClose={handleClose}
        setOpenModalCreate={setOpenModalCreate}
        handleCloseModalCreate={handleCloseModalCreate}
      />
    </>
  );
}
