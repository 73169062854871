import { useEffect, useState } from 'react';
import TreeNode from './TabValues/treeNode';
import { useSelector } from 'react-redux';
import { AuthService } from 'src/services';
import { AffilliateWapper, BinaryInner } from './styled';

const AffiliateTree = () => {
  const [nodeData, setNodeData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [Edges, setEdges] = useState([]);
  const [dataTree, setDataTree] = useState<any>();

  //on show affiliates tree
  // const onShowAffiliateTree = async () => {
  //   try {
  //     const res = await AuthService.affiliatesTree();
  //     setDataTree(res);
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  // useEffect(() => {
  //   onShowAffiliateTree();
  // }, []);

  const nodeTree = dataTree;

  useEffect(() => {
    setLoading(true);
    if (nodeTree) {
      const edgeType = 'smoothstep';
      let dataConver: any = [];
      let EdgesConver: any = [];
      const handleConvertData = (data: any, edges: any, number: any, preId: any) => {
        if (data.node) {
          if (dataConver.length !== 0) {
            EdgesConver = [
              ...EdgesConver,
              {
                id: `Left_Ref:${number}`,
                source: `${preId}`,
                target: `${number}`,
                type: edgeType,
                animated: true,
              },
            ];
          }
          dataConver = [...dataConver, { ...data.node, id: `${number}`, type: 'nodeElm' }];
        }
        if (data.left) {
          handleConvertData(data.left, EdgesConver, number + 1, number);
        }
        if (data.right) {
          handleConvertData(data.right, EdgesConver, `R_${number + 1}`, number);
        }
      };
      handleConvertData(nodeTree, EdgesConver, 0, null);
      setNodeData(dataConver);

      setEdges(EdgesConver);
      setLoading(false);
    }
  }, [nodeTree]);

  return (
    <>
      {!loading && (
        <BinaryInner>
          <TreeNode nodeData={nodeData} Edges={Edges} />
        </BinaryInner>
      )}
    </>
  );
};
export default AffiliateTree;
