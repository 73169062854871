import { ApexOptions } from "apexcharts";
import { alpha } from "@mui/material/styles";
import { Box, Card, Typography, Stack, CardProps } from "@mui/material";
import { fPercent, fShortenNumber } from "src/utils/formatNumber";
import Iconify from "../iconify";
import { CardContainer, CardImage } from "./styled";

// ----------------------------------------------------------------------

interface Props extends CardProps {
  title: string;
  total: number;
  percent?: number;
  image: string;
  backgroundColor: string;
  color: string;
  chart: {
    colors?: string[];
    series: number[];
    options?: ApexOptions;
  };
  isDolar?: string;
}

export default function CardCommon({
  title,
  percent,
  total,
  image,
  chart,
  backgroundColor,
  color,
  sx,
  isDolar,
  ...other
}: Props) {

  return (
    <CardContainer>
      <Card
        sx={{ display: "flex", alignItems: "center", px: 2, py: 1, ...sx }}
        {...other}
        className="card-common"
        style={{ backgroundColor: backgroundColor, color: color }}
      >
        <Box sx={{ flexGrow: 1 }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Box>
              <Typography variant="subtitle2" paragraph sx={{ mb: 0 }}>
                {title}
              </Typography>

              <Typography variant="h4" gutterBottom sx={{ mb: 0 }}>
                {isDolar}{fShortenNumber(total)}
              </Typography>
            </Box>
            <Box>
              <CardImage>
                <img src={image} width={30} height={30} alt="" />
              </CardImage>
            </Box>
          </Box>
        </Box>
      </Card>
    </CardContainer>
  );
}