import styled from "styled-components";

export const CardContainer = styled.div`
  .card-common {
    position: relative;
    z-index: 1;
    /* &:before {
      position: absolute;
      content: "";
      top: 0;
      left: -20px;
      background: url('/assets/images/card/shape-square.svg') center center / contain no-repeat;
      width: 240px;
      height: 240px;
      z-index: -1;
      opacity: 0.14;
    } */
  }
`;
export const CardImage = styled.div``;
