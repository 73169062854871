import { Button, Link, TableCell, TableRow } from "@mui/material";
import ConfirmDialog from "src/components/confirm-dialog";
import { shortenAddress } from "src/components/shortAddress";
import FormUpdateCoin from "./FormUpdateCoin";
import { useState } from "react";
import EditIcon from "@mui/icons-material/Edit";

export default function TableRowCoin({ row, onShowCoins }: any) {
  const [openUpdateUser, setOpenUpdateUser] = useState(false);

  //modal update user
  const handleCloseUpdateUser = () => {
    setOpenUpdateUser(false);
  };

  return (
    <>
      <ConfirmDialog
        open={openUpdateUser}
        onClose={handleCloseUpdateUser}
        title="Update Coin"
        content={
          <FormUpdateCoin
            row={row}
            handleCloseUpdateUser={handleCloseUpdateUser}
            onShowCoins={onShowCoins}
          />
        }
        disabledButton={true}
        action={false}
      />
      <TableRow hover>
        <TableCell
          align="left"
          style={{ borderBottom: "1px dashed rgba(241, 243, 244, 1)" }}
        >
          {shortenAddress(row?._id)}
        </TableCell>
        <TableCell
          align="left"
          style={{ borderBottom: "1px dashed rgba(241, 243, 244, 1)" }}
        >
          <Link
            href={`https://bscscan.com/address/${row?.contract}`}
            target="_blank"
            rel="noreferrer"
          >
            {shortenAddress(row?.contract)}
          </Link>
        </TableCell>
        <TableCell
          align="left"
          style={{ borderBottom: "1px dashed rgba(241, 243, 244, 1)" }}
        >
          {row?.coin}
        </TableCell>
        <TableCell
          align="left"
          style={{ borderBottom: "1px dashed rgba(241, 243, 244, 1)" }}
        >
          {row?.name}
        </TableCell>
        <TableCell
          align="left"
          style={{ borderBottom: "1px dashed rgba(241, 243, 244, 1)" }}
        >
          {row?.depositEnabled ? "True" : "False"}
        </TableCell>
        <TableCell
          align="left"
          style={{ borderBottom: "1px dashed rgba(241, 243, 244, 1)" }}
        >
          {row?.feeExternal}
        </TableCell>
        <TableCell
          align="left"
          style={{ borderBottom: "1px dashed rgba(241, 243, 244, 1)" }}
        >
          {row?.feeInternal}
        </TableCell>
        <TableCell
          align="left"
          style={{ borderBottom: "1px dashed rgba(241, 243, 244, 1)" }}
        >
          {/* {row?.isEnabled ? "True" : "False"} */}
          <Button
            variant="contained"
            color={row?.isEnabled ? "success" : "error"}
          >
            {row?.isEnabled ? "True" : "False"}
          </Button>
        </TableCell>
        <TableCell
          align="left"
          style={{ borderBottom: "1px dashed rgba(241, 243, 244, 1)" }}
        >
          {row?.minWithdrawal}
        </TableCell>
        <TableCell
          align="left"
          style={{ borderBottom: "1px dashed rgba(241, 243, 244, 1)" }}
        >
          <Button
            variant="contained"
            color={row?.withdrawEnabled ? "success" : "error"}
          >
            {row?.withdrawEnabled ? "True" : "False"}
          </Button>
        </TableCell>
        <TableCell
          align="left"
          style={{ borderBottom: "1px dashed rgba(241, 243, 244, 1)" }}
        >
          <div
            onClick={() => {
              setOpenUpdateUser(true);
            }}
          >
            <EditIcon />
          </div>
        </TableCell>
      </TableRow>
    </>
  );
}
