import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import FormProvider, { RHFTextField } from "src/components/hook-form";
import {
  BlockUpdateUser,
  FormSubmitButton,
  FormTitle,
  UpdateUserContainer,
} from "./styled";
import toast from "react-hot-toast";
import { Button } from "@mui/material";
import { AuthService } from "src/services";
import { useState } from "react";

interface FormValuesProps {
  TPTPrice: number;
  rootUser: string;
  dailyProfitPercent: number;
  maxOutUSDTPercent: number;
  maxOutTONPercent: number;
}

const FormUpdateUser = ({ row, handleCloseUpdate, onShowSettingShow }: any) => {
  const NewAddressSchema = Yup.object().shape({
    TPTPrice: Yup.number(),
    rootUser: Yup.string(),
    dailyProfitPercent: Yup.number(),
    maxOutUSDTPercent: Yup.number(),
    maxOutTONPercent: Yup.number(),
  });

  const defaultValues = {
    TPTPrice: row?.TPTPrice,
    rootUser: row?.rootUser,
    dailyProfitPercent: row?.dailyProfitPercent,
    maxOutUSDTPercent: row?.maxOutUSDTPercent,
    maxOutTONPercent: row?.maxOutTONPercent,
  };

  const methods = useForm<FormValuesProps>({
    resolver: yupResolver(NewAddressSchema),
    defaultValues,
  });

  const {
    handleSubmit,
  } = methods;

  const [isLoading, setIsLoading] = useState(false);
  const onSubmit = async (data: FormValuesProps) => {
    try {
      const res = await AuthService.updateSetting(data);
      if (res.data.message === "Success") {
        toast.success("Update setting successfully");
        setIsLoading(false);
        onShowSettingShow();
        handleCloseUpdate(false)
      }
    } catch (error) {
      console.error(error);
      toast.error("Some invalid input");
    }
  };

  return (
    <UpdateUserContainer>
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <BlockUpdateUser className="full">
          <FormTitle>
            <span>TPT Price</span>
          </FormTitle>
          <RHFTextField
            name="TPTPrice"
            label="TPT Price"
            defaultValue={row?.TPTPrice}
          />
        </BlockUpdateUser>
        <BlockUpdateUser className="full">
          <FormTitle>
            <span>Root User</span>
          </FormTitle>
          <RHFTextField
            name="rootUser"
            label="Root User"
            defaultValue={row?.rootUser}
          />
        </BlockUpdateUser>
        <BlockUpdateUser className="full">
          <FormTitle>
            <span>Daily Profit %</span>
          </FormTitle>
          <RHFTextField
            name="dailyProfitPercent"
            label="Daily Profit"
            defaultValue={row?.dailyProfitPercent}
          />
        </BlockUpdateUser>
        <BlockUpdateUser className="full">
          <FormTitle>
            <span>Maxout USDT</span>
          </FormTitle>
          <RHFTextField
            name="maxOutUSDTPercent"
            label="Maxout USDT"
            defaultValue={row?.maxOutUSDTPercent}
          />
        </BlockUpdateUser>
        <BlockUpdateUser className="full">
          <FormTitle>
            <span>Maxout TON</span>
          </FormTitle>
          <RHFTextField
            name="maxOutTONPercent"
            label="Maxout Ton"
            defaultValue={row?.maxOutTONPercent}
          />
        </BlockUpdateUser>
        <BlockUpdateUser className="full">
          <FormSubmitButton className="active" disabled={isLoading}>
            Update
          </FormSubmitButton>
          <Button
            variant="outlined"
            color="inherit"
            onClick={handleCloseUpdate}
          >
            Cancel
          </Button>
        </BlockUpdateUser>
      </FormProvider>
    </UpdateUserContainer>
  );
};

export default FormUpdateUser;
