import { Helmet } from 'react-helmet-async';
// @mui
import {
  Container,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Pagination,
  Box,
  Stack,
  Button,
} from '@mui/material';
// _mock_
import {
  _ecommerceNewProducts,
  _ecommerceSalesOverview,
  _ecommerceBestSalesman,
  _ecommerceLatestProducts,
} from '../../_mock/arrays';
// components
import { useSettingsContext } from '../../components/settings';
// assets
import { useEffect, useState } from 'react';
import moment from 'moment';
import { AuthService } from 'src/services';
import { LoadingDataTable } from './styled';
import LoadingData from 'src/components/loadingData';
import AddIcon from '@mui/icons-material/Add';
import ModalCreateInvestment from './ModalCreateInvestment';
import Iconify from 'src/components/iconify';
import ModalUpdateInvestment from './ModalUpdateInvestment';
import ModalCreateLevel from './ModalCreateLevel';
import ModalUpdateLevels from './ModalUpdateLevel';
import EmptyData from 'src/components/empty-data';
import { shortenAddress } from 'src/components/shortAddress';
import iconCopy from '../../assets/image-common/icn_copy.svg';
import CopyToClipboard from 'react-copy-to-clipboard';
import toast from 'react-hot-toast';

// ----------------------------------------------------------------------

export default function GeneralLevel() {
  const [dataTable, setDataTable] = useState<any>();
  console.log(dataTable);
  const [page, setPage] = useState(1);
  const { themeStretch } = useSettingsContext();
  const [loadingData, setLoadingData] = useState(false);
  const [openModalCreate, setOpenModalCreate] = useState(false);
  const [openModalInvestment, setOpenModalInvestment] = useState(false);
  const [dataRow, setDataRow] = useState();

  // show user manage
  const onShowLevels = async () => {
    setLoadingData(true);
    try {
      const params = {
        page,
        perPage: 15,
      };
      const res = await AuthService.level(params);
      setDataTable(res);
      setLoadingData(false);
    } catch (error) {
      setLoadingData(false);
      console.log(error);
    }
  };

  useEffect(() => {
    onShowLevels();
  }, [page]);

  //table
  const rows = dataTable?.data;

  const handleChangePage = (e: any, value: any) => {
    setPage(value);
  };

  // handle create investment
  const handleCloseModalCreate = () => setOpenModalCreate(false);
  const handleCreateInvestment = () => {
    setOpenModalCreate(true);
  };

  //handle update investment
  const handleOpenModalUpdateInvestment = (data: any) => {
    setOpenModalInvestment(true);
    setDataRow(data);
  };
  const handleCloseModalUpdateInvestment = () => {
    setOpenModalInvestment(false);
  };

  return (
    <>
      <Helmet>
        <title> Level | Ton-patrick </title>
      </Helmet>

      <Container maxWidth={themeStretch ? false : 'xl'} style={{ position: 'relative' }}>
        <Stack spacing={2} direction="row" justifyContent="flex-end" mb={3}>
          <Button onClick={() => handleCreateInvestment()} variant="outlined" endIcon={<AddIcon />}>
            Create
          </Button>
        </Stack>
        <TableContainer component={Paper} style={{ minHeight: '928px', marginTop: '24px' }}>
          <Table sx={{ minWidth: 650 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>No</TableCell>
                <TableCell align="left">Level Id</TableCell>
                <TableCell align="left">Level</TableCell>
                <TableCell align="left">Name</TableCell>
                <TableCell align="left">Profit</TableCell>
                <TableCell align="left">Created At</TableCell>
                <TableCell align="left">Updated At</TableCell>
                <TableCell align="left">Action</TableCell>
              </TableRow>
            </TableHead>
            {loadingData ? (
              <LoadingDataTable>
                <LoadingData />
              </LoadingDataTable>
            ) : (
              <TableBody>
                {rows && rows?.length > 0 ? (
                  rows.map((row: any, index: number) => (
                    <TableRow
                      key={row.code}
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                      <TableCell component="th" scope="row">
                        {index + 1}
                      </TableCell>
                      <TableCell align="left" style={{ display: 'flex' }}>
                        <CopyToClipboard
                          text={row?.level_id}
                          onCopy={() => toast.success('Coppied !')}
                        >
                          <div style={{ display: 'flex' }}>
                            <span>{shortenAddress(row?.level_id)}</span>
                            <img style={{ cursor: 'pointer' }} src={iconCopy} alt="Copy" />
                          </div>
                        </CopyToClipboard>
                      </TableCell>
                      <TableCell align="left">{row?.level}</TableCell>
                      <TableCell align="left">{row?.name}</TableCell>
                      <TableCell align="left">{row?.profit}</TableCell>
                      <TableCell align="left">
                        {moment(row?.created_at).format('DD/MM/YYYY') +
                          ' - ' +
                          moment(row?.created_at).format('HH:mm:ss')}
                      </TableCell>
                      <TableCell align="left">
                        {moment(row?.updated_at).format('DD/MM/YYYY') +
                          ' - ' +
                          moment(row?.updated_at).format('HH:mm:ss')}
                      </TableCell>
                      <TableCell align="center">
                        <Iconify
                          style={{ cursor: 'pointer' }}
                          icon="eva:edit-fill"
                          onClick={() => {
                            handleOpenModalUpdateInvestment(row);
                          }}
                        />
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <EmptyData />
                )}
              </TableBody>
            )}
          </Table>
        </TableContainer>
        <Pagination
          style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '30px' }}
          onChange={handleChangePage}
          count={Math.ceil(dataTable?.total / 15)}
        />
      </Container>
      <ModalCreateLevel
        open={openModalCreate}
        setOpenModalCreate={setOpenModalCreate}
        handleCloseModalCreate={handleCloseModalCreate}
        onShowLevels={onShowLevels}
      />
      <ModalUpdateLevels
        open={openModalInvestment}
        handleCloseModalUpdateInvestment={handleCloseModalUpdateInvestment}
        dataRow={dataRow}
        onShowLevels={onShowLevels}
      />
    </>
  );
}
