import { Helmet } from "react-helmet-async";
import { useEffect, useRef, useState } from "react";
import sumBy from "lodash/sumBy";
// @mui
import {
  Tab,
  Tabs,
  Card,
  Table,
  Divider,
  TableBody,
  Container,
  TableContainer,
} from "@mui/material";
// _mock_
import { _invoices } from "../../../_mock/arrays";
import Label from "../../../components/label";
import Scrollbar from "../../../components/scrollbar";
import CustomBreadcrumbs from "../../../components/custom-breadcrumbs";
import { useSettingsContext } from "../../../components/settings";
import {
  useTable,
  TableNoData,
  TableHeadCustom,
  TablePaginationCustom,
} from "../../../components/table";
// sections
import { InvoiceTableToolbar } from "../../../sections/@dashboard/invoice/list";
import { AuthService } from "src/services";
import InvoiceTableRow from "./TableRow";
import moment from "moment";

// ----------------------------------------------------------------------

const SERVICE_OPTIONS = [
  "all",
  "AFFILIATES_BONUS",
  "INVESTMENT",
  "PROFIT_DAILY",
  "BRANCH_BONUS",
  "RANKING_BONUS",
  "WITHDRAWAL",
  "CLAIM",
  "RANKING_BONUS_DAILY",
  "INVESTMENT_BONUS",
];

const TABLE_HEAD = [
  { id: "date", label: "Create Date", align: "left" },
  { id: "addressUser", label: "Address User", align: "left" },
  { id: "type", label: "Type", align: "left" },
  { id: "amoutUsd", label: "Amout (USDT)", align: "left", width: 140 },
  { id: "token", label: "Token Value", align: "left" },
  { id: "description", label: "Description", align: "left", width: 140 },
];

// ----------------------------------------------------------------------

export default function GeneralTransaction({ data }: any) {
  const { themeStretch } = useSettingsContext();

  const {
    dense,
    page,
    order,
    orderBy,
    rowsPerPage,
    setPage,
    //
    selected,
    //
    onSort,
    onChangeDense,
    onChangePage,
    onChangeRowsPerPage,
  } = useTable({ defaultOrderBy: "createDate" });

  const [tableData, setTableData] = useState<any>();
  const [currentPage, setCurrenPage] = useState<any>(1);
  const [filterName, setFilterName] = useState("");
  const [filterStatus, setFilterStatus] = useState("");
  const [filterService, setFilterService] = useState("");
  const [filterEndDate, setFilterEndDate] = useState<any>(null);
  const [filterStartDate, setFilterStartDate] = useState<any>(null);
  const [filterType, setFilterType] = useState("");

  const isFiltered =
    filterStatus !== "" ||
    filterName !== "" ||
    filterService !== "" ||
    (!!filterStartDate && !!filterEndDate);

  const TABS = [
    { value: "", label: "All", color: "info", count: currentPage?.total },
    {
      value: "wd",
      label: "Withdraw",
      color: "warning",
    },
    {
      value: "tf",
      label: "Transfer",
      color: "error",
    },
    {
      value: "rc",
      label: "Reward Commission",
      color: "default",
    },
    {
      value: "dc",
      label: "Direct Commission",
      color: "default",
    },
    {
      value: "ic",
      label: "Indirect Commission",
      color: "default",
    },
    {
      value: "iv",
      label: "Invest",
      color: "default",
    },
    {
      value: "ci",
      label: "Cancel Invest",
      color: "default",
    },
  ] as const;

  const handleFilterStatus = (
    event: React.SyntheticEvent<Element, Event>,
    newValue: string
  ) => {
    setPage(0);
    setFilterType(newValue);
  };

  const handleFilterName = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPage(0);
    setFilterName(event.target.value);
  };

  const handleFilterService = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPage(0);
    setFilterService(event.target.value);
  };

  const handleResetFilter = () => {
    setFilterName("");
    setFilterStatus("");
    setFilterService("");
    setFilterEndDate("");
    setFilterStartDate("");
  };

  const handleTransaction = async () => {
    const params: any = {
      page: page + 1,
      limit: rowsPerPage,
      filterBy: {},
    };
    if (filterName && filterType) {
      params.filterBy.address = filterName;
      params.filterBy.type = filterType;
    }
    if (filterName) {
      params.filterBy.address = filterName;
    }
    if (filterType) {
      params.filterBy.type = filterType;
    }
    try {
      const res = await AuthService.getBalanceHistory(params);
      if (res) {
        setTableData(res?.data?.data);
        setCurrenPage(res?.data.total);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    handleTransaction();
  }, [filterName, filterType, page, rowsPerPage]);

  //handle scroll top
  const ref = useRef<any>(null);
  const handleScrollTop = () => {
    ref.current?.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <>
      <Helmet>
        <title> Transaction | Ton-patrick</title>
      </Helmet>

      <Container maxWidth={themeStretch ? false : "lg"}>
        <CustomBreadcrumbs
          heading="Transaction"
          links={[
            {
              name: "Transaction",
            },
          ]}
        />

        <Card>
          <Tabs
            value={filterStatus}
            onChange={handleFilterStatus}
            sx={{
              px: 2,
              bgcolor: "background.neutral",
            }}
          >
            {TABS.map((tab: any) => (
              <Tab
                key={tab.value}
                value={tab.value}
                label={tab.label}
                icon={
                  <>
                    {tab?.count && (
                      <Label color={tab.color} sx={{ mr: 1 }}>
                        {tab.count}
                      </Label>
                    )}
                  </>
                }
              />
            ))}
          </Tabs>
          <Divider />
          <InvoiceTableToolbar
            isFiltered={isFiltered}
            filterName={filterName}
            filterService={filterService}
            filterEndDate={filterEndDate}
            onFilterName={handleFilterName}
            optionsService={SERVICE_OPTIONS}
            onResetFilter={handleResetFilter}
            filterStartDate={filterStartDate}
            onFilterService={handleFilterService}
            onFilterStartDate={(newValue) => {
              setFilterStartDate(moment(newValue).format("YYYY-MM-DD"));
            }}
            onFilterEndDate={(newValue) => {
              setFilterEndDate(moment(newValue).format("YYYY-MM-DD"));
            }}
            hiddenSelect={true}
            hiddenSearchDate={true}
          />

          <TableContainer sx={{ position: "relative", overflow: "unset" }}>
            <Scrollbar>
              <Table size={dense ? "small" : "medium"} sx={{ minWidth: 800 }}>
                <TableHeadCustom
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={tableData?.length}
                  numSelected={selected?.length}
                  onSort={onSort}
                />

                <TableBody>
                  {tableData && tableData?.length > 0 ? (
                    tableData?.map((row: any) => (
                      <InvoiceTableRow key={row?.transaction_id} row={row} />
                    ))
                  ) : (
                    <TableNoData isNotFound={true} />
                  )}
                </TableBody>
              </Table>
            </Scrollbar>
          </TableContainer>

          <TablePaginationCustom
            count={currentPage}
            page={page}
            rowsPerPage={rowsPerPage}
            onPageChange={onChangePage}
            onRowsPerPageChange={onChangeRowsPerPage}
            //
            dense={dense}
            onChangeDense={onChangeDense}
            onClick={handleScrollTop}
          />
        </Card>
      </Container>
    </>
  );
}
