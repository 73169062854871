import styled from "@emotion/styled";

export const EmptyDataContainer = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  img {
    width: 250px;
    height: 250px;
  }
`