import { Helmet } from "react-helmet-async";
import { useEffect, useRef, useState } from "react";
// @mui
import {
  Card,
  Table,
  Stack,
  Tooltip,
  TableBody,
  Container,
  IconButton,
  TableContainer,
} from "@mui/material";
// routes
import { PATH_DASHBOARD } from "../../../routes/paths";
// utils
// _mock_
import { _invoices } from "../../../_mock/arrays";
// @types
// components
import Iconify from "../../../components/iconify";
import Scrollbar from "../../../components/scrollbar";
import CustomBreadcrumbs from "../../../components/custom-breadcrumbs";
import { useSettingsContext } from "../../../components/settings";
import {
  useTable,
  TableNoData,
  TableHeadCustom,
  TableSelectedAction,
  TablePaginationCustom,
} from "../../../components/table";
// sections
import { InvoiceTableToolbar } from "../../../sections/@dashboard/invoice/list";
import { AuthService } from "src/services";
import InvoiceTableRow from "./TableRow";

const SERVICE_OPTIONS = ["all"];

const TABLE_HEAD = [
  { id: "_id", label: "ID", align: "left" },
  { id: "address", label: "Address", align: "left" },
  { id: "rank", label: "Rank", align: "left" },
  // { id: "investmentToken", label: "Investment (Token)", align: "left" },
  { id: "investmentUSD", label: "Investment (USD)	", align: "left" },
  { id: "balanceToken", label: "Balance (Token)", align: "left" },
  { id: "balanceUSD", label: "Balance (USDT)", align: "left" },
  { id: "profitDaily", label: "Profit Daily (Token)", align: "left" },
  { id: "totalRevenue", label: "Total Revenue (USD)", align: "left" },
  // { id: "package", label: "Package", align: "left" },
  { id: "sponser", label: "Sponser", align: "left" },
  { id: "support", label: "Support", align: "left" },
  { id: "lock", label: "Lock", align: "left" },
  { id: "withdraw", label: "Withdrawal", align: "left" },
  { id: "transfer", label: "Transfer", align: "left" },
  { id: "edit", label: "Edit", align: "left" },
];

// ----------------------------------------------------------------------

export default function GeneralUser({ data }: any) {
  const { themeStretch } = useSettingsContext();

  const {
    dense,
    page,
    order,
    orderBy,
    rowsPerPage,
    setPage,
    //
    selected,
    onSelectAllRows,
    //
    onSort,
    onChangeDense,
    onChangePage,
    onChangeRowsPerPage,
  } = useTable({ defaultOrderBy: "createDate" });

  const [tableData, setTableData] = useState<any>(data);
  const [currentPage, setCurrenPage] = useState<any>(1);
  const [filterName, setFilterName] = useState("");

  const [filterStatus, setFilterStatus] = useState("");

  const [filterService, setFilterService] = useState("");

  const [filterEndDate, setFilterEndDate] = useState<Date | null>(null);

  const [filterStartDate, setFilterStartDate] = useState<Date | null>(null);

  const handleFilterName = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPage(0);
    setFilterName(event.target.value);
    console.log(event.target.value)
  };

  const handleFilterService = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPage(0);
    setFilterService(event.target.value);
  };

  const handleResetFilter = () => {
    setFilterName("");
    setFilterStatus("");
    setFilterService("");
    setFilterEndDate(null);
    setFilterStartDate(null);
  };

  // show Investment list
  const onShowUser = async () => {
    try {
      const params = filterName
        ? {
            page: page + 1,
            limit: rowsPerPage,
            filterBy: {
              address: filterName,
            },
          }
        : { page: page + 1, limit: rowsPerPage };
      const res = await AuthService.user(params);
      setTableData(res?.data?.data);
      console.log(res?.data?.data);
      setCurrenPage(res?.data.total);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    onShowUser();
  }, [page, filterName, filterEndDate, filterStatus, rowsPerPage]);

  //handle scroll top
  const ref = useRef<any>(null);
  const handleScrollTop = () => {
    ref.current?.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <>
      <Helmet>
        <title> Manage Wallet | Ton-patrick</title>
      </Helmet>

      <Container maxWidth={themeStretch ? false : "lg"}>
        <CustomBreadcrumbs
          heading="Manage Wallet"
          links={[
            {
              name: "Dashboard",
              href: PATH_DASHBOARD.root,
            },
            {
              name: "Wallet",
            },
          ]}
        />

        <Card ref={ref}>
          <InvoiceTableToolbar
            filterName={filterName}
            filterService={filterService}
            filterEndDate={filterEndDate}
            onFilterName={handleFilterName}
            optionsService={SERVICE_OPTIONS}
            onResetFilter={handleResetFilter}
            filterStartDate={filterStartDate}
            onFilterService={handleFilterService}
            onFilterStartDate={(newValue) => {
              setFilterStartDate(newValue);
            }}
            onFilterEndDate={(newValue) => {
              setFilterEndDate(newValue);
            }}
            hiddenSelect={true}
            hiddenSearchDate={true}
          />

          <TableContainer sx={{ position: "relative", overflow: "unset" }}>
            <TableSelectedAction
              dense={dense}
              numSelected={selected?.length}
              rowCount={tableData?.length}
              onSelectAllRows={(checked) =>
                onSelectAllRows(
                  checked,
                  tableData?.map((row: any) => row.id)
                )
              }
              action={
                <Stack direction="row">
                  <Tooltip title="Sent">
                    <IconButton color="primary">
                      <Iconify icon="ic:round-send" />
                    </IconButton>
                  </Tooltip>

                  <Tooltip title="Download">
                    <IconButton color="primary">
                      <Iconify icon="eva:download-outline" />
                    </IconButton>
                  </Tooltip>

                  <Tooltip title="Print">
                    <IconButton color="primary">
                      <Iconify icon="eva:printer-fill" />
                    </IconButton>
                  </Tooltip>

                  <Tooltip title="Delete">
                    <IconButton color="primary">
                      <Iconify icon="eva:trash-2-outline" />
                    </IconButton>
                  </Tooltip>
                </Stack>
              }
            />

            <Scrollbar>
              <Table size={dense ? "small" : "medium"} sx={{ minWidth: 800 }}>
                <TableHeadCustom
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={tableData?.length}
                  numSelected={selected?.length}
                  onSort={onSort}
                />

                <TableBody>
                  {tableData && tableData?.length > 0 ? (
                    tableData?.map((row: any) => (
                      <InvoiceTableRow
                        key={row?.user?.address}
                        row={row}
                        onShowUser={onShowUser}
                      />
                    ))
                  ) : (
                    <TableNoData isNotFound={true} />
                  )}
                </TableBody>
              </Table>
            </Scrollbar>
          </TableContainer>

          <TablePaginationCustom
            count={currentPage}
            page={page}
            rowsPerPage={rowsPerPage}
            onPageChange={onChangePage}
            onRowsPerPageChange={onChangeRowsPerPage}
            //
            dense={dense}
            onChangeDense={onChangeDense}
            onClick={handleScrollTop}
          />
        </Card>
      </Container>
    </>
  );
}
