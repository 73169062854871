import styled from "styled-components";

export const LoadingDataTable = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;
export const BlockButtons = styled.div`
  display: flex;
  button {
    background-color: unset;
    border: unset;
    cursor: pointer;
    &:nth-child(1) {
      color: green;
      margin-right: 3px;
    }
    &:nth-child(2) {
      color: red;
      margin-left: 3px;
    }
  }
`;

export const FormContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 10px;
`
export const InputRow = styled.div``